import React from 'react';
import { Alert, FieldError } from '@datum-components/react-components';

interface Props {
  errors?: FieldError[];
  clearErrors: () => void;
}

function FieldErrorRow({ error }: { error: FieldError }) {
  const errorMessage = typeof error.errorMessage === 'string' ? error.errorMessage : error.errorMessage.join('. ');
  return (
    <li>
      <span className="field-error-label">{error.propLabel}</span>: {errorMessage}
    </li>
  );
}

const ValidationWarnings: React.FC<Props> = ({ errors, clearErrors }) => {
  if (errors && errors.length) {
    return (
      <div className="form-validation-info">
        <Alert
          fullWidth={true}
          status="warning"
          onClose={clearErrors}
          close={true}
          close-aria-label="Close form warning information"
        >
          <Alert.Title>Form validation warning</Alert.Title>
          <Alert.Description>
            <ul>
              {errors.map((_error, _i) => (
                <FieldErrorRow key={_i} error={_error} />
              ))}
            </ul>
          </Alert.Description>
        </Alert>
      </div>
    );
  }

  return <></>;
};

export default ValidationWarnings;
