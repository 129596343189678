import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { DatePicker, Divider, Form, Select, Table, notification } from 'antd';
import React, { useState } from 'react';
import { getDocumentStatusReports, getFilterOptions } from 'api/dashbard';
import { DATE_FORMAT } from 'utils/reportsDateFormat';
import { Button } from '@datum-components/react-components';
import moment from 'moment';
import { dlDocumentStatusReportsCsv } from 'api/reports';

function DocumentStatusReports() {
  const { accessToken } = useOidcAccessToken();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [docStatusReportList, setDocStatusReportList] = useState([]);
  const [docStatusQueries, setDocStatusQueries] = useState<any>({
    claimType: [
      /*'HCFA', 'UB04', 'DENTAL'*/
    ],
    lob: [
      // 'GA - DSNP',
      // 'GA - Marketplace',
      // 'IN - DSNP',
      // 'IN - Marketplace',
      // 'IN - Medicaid',
      // 'MI - Medicaid',
      // 'NC - Marketplace',
      // 'OH - DSNP',
      // 'OH - Marketplace',
      // 'OH - Medicaid',
      // 'OH - MyCare',
      // 'GA - Medicaid',
      // 'KY - Marketplace',
      // 'AR - Medicaid',
      // 'WV - Marketplace',
      // 'IA - IOWA Medicaid',
      // 'MS - MISSISIPI Medicaid',
      // 'MI - Marketplace'
    ],
    state: [
      // 'Ohio',
      // 'Indiana',
      // 'Kentucky',
      // 'Georgia',
      // 'West Virginia',
      // 'North Carolina',
      // 'Arkansas',
      // 'Mississippi',
      // 'Ohio/All',
      // 'Michigan'
    ],
    incomingMode: [
      /*'PORTAL'*/
    ]
  });

  React.useEffect(() => {
    // documentStatusReportFunction();
    filterOptionsFunction();
  }, []);

  const filterOptionsFunction = async (values?: any) => {
    if (accessToken) {
      try {
        setLoading(true);
        const data = await getFilterOptions(accessToken);

        if (data) {
          setDocStatusQueries(data);
        }
      } catch (error: any) {
        console.error('An error occurred:', error);
        notification.error({
          message: 'Could not fetch Filter Options'
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const dlReports = async (values: any) => {
    try {
      await form.validateFields();
    } catch (e) {
      return;
    }

    const tenDaysBeforeToday = moment().startOf('day').subtract(10, 'days');

    const dateFrom = values.dateFrom?.format('YYYY-MM-DD');
    const dateTo = values.dateTo?.format('YYYY-MM-DD');
    let portalFrom = values.portalDateFrom;

    // Check if portalFrom is older than tenDaysBeforeToday
    if (portalFrom && portalFrom.isBefore(tenDaysBeforeToday)) {
      portalFrom = tenDaysBeforeToday;
    }

    const portalDateFrom = portalFrom.format('YYYY-MM-DD');
    const portalDateTo = values.portalDateTo?.format('YYYY-MM-DD');

    const transformdValues = { ...values, dateFrom, dateTo, portalDateFrom, portalDateTo };

    if (accessToken) {
      try {
        const data = await dlDocumentStatusReportsCsv({
          token: accessToken,
          dateFrom: transformdValues.dateFrom && transformdValues.dateFrom,
          dateTo: transformdValues.dateTo && transformdValues.dateTo,
          portalDateFrom: transformdValues.portalDateFrom && transformdValues.portalDateFrom,
          portalDateTo: transformdValues.portalDateTo && transformdValues.portalDateTo,
          lob: transformdValues.lob && transformdValues.lob,
          claimType: transformdValues.claimType && transformdValues.claimType,
          state: transformdValues.state && transformdValues.state,
          incomingMode: transformdValues.incomingMode && transformdValues.incomingMode
        });
        const blob = new Blob([data], { type: 'text/csv' });
        const blobUrl = URL.createObjectURL(blob);

        const anchor = document.createElement('a');
        anchor.href = blobUrl;
        anchor.download = 'documents_report.csv';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(blobUrl);
      } catch (e) {
        console.error(e);
        notification.error({ message: 'Download failed' });
      }
    }
  };

  const documentStatusReportFunction = async (values: any = {}) => {
    const dateFrom = values.dateFrom?.format('YYYY-MM-DD');
    const dateTo = values.dateTo?.format('YYYY-MM-DD');
    const portalDateFrom = values.portalDateFrom?.format('YYYY-MM-DD');
    const portalDateTo = values.portalDateTo?.format('YYYY-MM-DD');
    const transformdValues = { ...values, dateFrom, dateTo, portalDateFrom, portalDateTo };

    if (accessToken) {
      try {
        const data = await getDocumentStatusReports({
          token: accessToken,
          dateFrom: transformdValues.dateFrom && transformdValues.dateFrom,
          dateTo: transformdValues.dateTo && transformdValues.dateTo,
          portalDateFrom: transformdValues.portalDateFrom && transformdValues.portalDateFrom,
          portalDateTo: transformdValues.portalDateTo && transformdValues.portalDateTo,
          lob: transformdValues.lob && transformdValues.lob,
          claimType: transformdValues.claimType && transformdValues.claimType,
          state: transformdValues.state && transformdValues.state,
          incomingMode: transformdValues.incomingMode && transformdValues.incomingMode
        });
        if (data) {
          setDocStatusReportList(data);
        }
      } catch (error: any) {
        console.error('An error occurred:', error);
        notification.error({
          message: 'Could not fetch Document Reports'
        });
      } finally {
        //
      }
    }
  };

  const columns: any = [
    {
      title: 'CLAIM TYPE',
      dataIndex: 'claimType',
      key: 'claimType',
      fixed: 'left'
    },
    {
      title: 'DCN',
      dataIndex: 'dcn',
      key: 'dcn',
      fixed: 'left'
    },
    {
      title: 'DOCUMENT NUMBER',
      dataIndex: 'documentNumber',
      key: 'documentNumber'
    },
    {
      title: 'INCOMING MODE',
      dataIndex: 'incomingMode',
      key: 'incomingMode'
    },
    {
      title: 'LOB',
      dataIndex: 'lob',
      key: 'lob'
    },
    {
      title: 'STATUS',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'RECEIVED DATE',
      dataIndex: 'submissionDate',
      key: 'receivedDate',
      render: (date: string) => <div>{date ? moment(date).format('MMM DD. YYYY') : ''}</div>
    },
    {
      title: 'STATE',
      dataIndex: 'state',
      key: 'state'
    },
    {
      title: 'SUBMISSION USER',
      dataIndex: 'subUser',
      key: 'subUser'
    },
    {
      title: 'PROCESSED DATE',
      dataIndex: 'submissionDate',
      key: 'submissionDate',
      render: (date: string) => <div>{date ? moment(date).format('MMM DD. YYYY') : ''}</div>
    }
  ];

  return (
    <div className="branding-wrapper">
      <div className="box-wrapper">
        <div style={{ padding: '1rem 3rem 0em' }}>
          <Divider orientation="left">
            <b>DOCUMENT STATUS REPORTS</b>
          </Divider>
        </div>
        <div style={{ marginTop: '2rem' }}>
          <Form
            name="basic"
            layout="vertical"
            form={form}
            autoComplete="off"
            onFinish={documentStatusReportFunction}
            style={{ display: 'flex', alignItems: 'flex-end', gap: '1rem', padding: '0 1rem' }}
          >
            <Form.Item name={'dateFrom'} label={<>Received Date From</>}>
              <DatePicker suffixIcon={null} format={DATE_FORMAT} placeholder={DATE_FORMAT} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item name={'dateTo'} label={<>Received Date To</>}>
              <DatePicker suffixIcon={null} format={DATE_FORMAT} placeholder={DATE_FORMAT} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name="portalDateFrom"
              label={
                <>
                  <span style={{ color: 'red' }}>* </span> Portal Creation Date From
                </>
              }
              rules={[{ required: true, message: 'Please input your Portal Creation Date From!' }]}
            >
              <DatePicker suffixIcon={null} format={DATE_FORMAT} placeholder={DATE_FORMAT} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item
              name="portalDateTo"
              label={
                <>
                  <span style={{ color: 'red' }}>* </span> Portal Creation Date To
                </>
              }
              rules={[{ required: true, message: 'Please input your Portal Creation Date To!' }]}
            >
              <DatePicker suffixIcon={null} format={DATE_FORMAT} placeholder={DATE_FORMAT} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="LOB" name="lob">
              <Select
                mode="multiple"
                loading={loading}
                style={{ width: '150px' }}
                placeholder="Please select"
                allowClear
                options={docStatusQueries?.lob?.map((el: any) => ({ label: el, value: el }))}
                showSearch
              />
            </Form.Item>
            <Form.Item label="Incoming Mode" name="incomingMode">
              <Select
                mode="multiple"
                loading={loading}
                style={{ width: '150px' }}
                placeholder="Please select"
                allowClear
                options={docStatusQueries?.incomingMode?.map((el: any) => ({ label: el, value: el }))}
                showSearch
              />
            </Form.Item>
            <Form.Item label="Claim Type" name="claimType">
              <Select
                mode="multiple"
                loading={loading}
                style={{ width: '150px' }}
                placeholder="Please select"
                allowClear
                options={docStatusQueries?.claimType?.map((el: any) => ({ label: el, value: el }))}
                showSearch
              />
            </Form.Item>
            <Form.Item label="State" name="state">
              <Select
                mode="multiple"
                loading={loading}
                style={{ width: '150px' }}
                placeholder="Please select"
                allowClear
                options={docStatusQueries?.state?.map((el: any) => ({ label: el, value: el }))}
                showSearch
              />
            </Form.Item>
            <Form.Item>
              <div className="theme ant-col theme ant-form-item-label">
                <label htmlFor="basic_filter" title="Filter" style={{ opacity: 0 }}>
                  Filter
                </label>
              </div>
              <Button style={{ width: '100%' }} variant="primary">
                Reports
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div style={{ padding: '0 1rem' }}>
          <Table
            scroll={{ x: 1300 }}
            bordered
            style={{ width: '100%' }}
            dataSource={docStatusReportList}
            columns={columns}
          />
        </div>

        <div style={{ margin: '24px 0px 24px 1rem' }}>
          <Button onClick={() => dlReports(form.getFieldsValue())} variant="primary">
            Download reports
          </Button>
        </div>
      </div>
    </div>
  );
}

export default DocumentStatusReports;
