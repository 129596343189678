import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Radio, Input, Select, Divider, Row, Col, Switch, Tooltip, RadioChangeEvent } from 'antd';
import { DATE_FORMAT } from '../../utils/hcfaConstants';
import { DIAGNOSIS_CODES_MAP, HCFA_FIELDS } from '../../utils/hcfa/fields';
import {
  BILLING_PROVIDER_ID_QUALITY_CODES_HCFA,
  CLAIM_QUALITY_CODES,
  DIAGNOSIS_CODES,
  FACILITY_ID_QUALITY_CODES_HCFA,
  HCFA33PhysicianEnum,
  OTHER_QUALITY_CODES_HCFA,
  QUALITY_CODES,
  REFERRING_PROVIDER_ID_QUALITY_CODES,
  REFERRING_PROVIDER_QUALITY_CODES,
  RESUBMISSION_CODES_HCFA,
  SIGNATURE_OPTIONS
} from '../../utils/hcfa/constants';
import SelectState from '../../components/hcfa/shared/SelectState';
import FieldsetFullName from '../../components/hcfa/shared/FieldsetFullName';
import { FormContext } from '../common/FormProvider';
import FormItemPatientBirthday from './shared/FormItemPatientBirthday';
import FormItemSex from './shared/FormItemSex';
import FormItemPatientRelationship from './shared/FormItemPatientRelationship';
import FieldsetLocation from './shared/FieldsetLocation';
import FormItemEmployment from './shared/FormItemEmployment';
import FormErrorList from './shared/FormErrorList';
import ServicesFieldsList from './ServicesFieldsList';
import '../forms/paper-form.scss';
import { getYupValidator, getYupWarning } from 'utils/hcfa/validations';
import { toCurrency, toCurrencyPaste } from 'helpers/toCurrency';

import { toPositiveNumber } from 'helpers/toPositiveNumber';
import { useParams, usePrompt } from 'react-router-dom';
import { FORM_ACTIONS } from 'config/constants';
import { FlagOutlined, InfoCircleOutlined, SendOutlined } from '@ant-design/icons';
import { HcfaFormValueChange, HcfaTableChange } from 'utils/hcfa/customRules';
import { getRevalidateFields, revalidateTable } from 'helpers/revalidateHcfaTable';
import { Button } from '@datum-components/react-components';
import { onSearchSelect } from 'utils/search/search';
import DatePickerAutoPopulate from 'components/common/DatePickerAutoPopulate';
import { nullifyRadioGroup } from 'helpers/nullifyRadioGroup';
import { getFormProvidersDataFromGlobalObject } from 'utils/hcfa/putFormProvidersDataInGlobalObject';
import { toAlphaNumDotUppercase } from 'helpers/toAlphaNumDot';
import { useAccessTokenProvidedInfo } from 'utils/hooks/useAccessTokenProvidedInfo';
import { useHotkeys } from 'react-hotkeys-hook';

function PaperForm(): JSX.Element {
  const { t } = useTranslation();
  const {
    form,
    errorInfo,
    setErrorInfo,
    setFullErrorInfo,
    fullErrorInfo,
    revalidateForm,
    isFormDirty,
    setIsFormDirty,
    fullWarningInfo,
    setData,
    handleQuickSave
    // firstValidationPerformed
  } = useContext(FormContext);

  const { action } = useParams();
  const disabled = action === FORM_ACTIONS.VIEW;
  const isNew = action === FORM_ACTIONS.NEW;
  const [disabledSwitch, setDisabledSwitch] = useState(false);
  const {
    // LobOidc,
    npiOidc,
    providerAddressOidc,
    providerAddressOtherOidc,
    providerCityOidc,
    providerNameOidc,
    providerStateOidc,
    providerZipCodeOidc,
    taxID
    // userAddressOidc,
    // userBillingOidc
  } = useAccessTokenProvidedInfo();

  const physicianValue33 = Form.useWatch(HCFA_FIELDS.HCFA33psysiciancheckbox, form) || HCFA33PhysicianEnum.supplier;

  const isAmbulance = Form.useWatch(HCFA_FIELDS.isAmbulance, form);

  useEffect(() => {
    setErrorInfo([]);
    setFullErrorInfo([]);
  }, []);

  useHotkeys(
    'ctrl+s, command+s',
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();

      handleQuickSave('hcfa');
    },
    { enableOnFormTags: true }
  );

  useEffect(() => {
    if (isNew) {
      const toSet = {
        [HCFA_FIELDS.billingName]: providerNameOidc,
        [HCFA_FIELDS.billingAddress]: providerAddressOidc,
        // [HCFA_FIELDS.billingId]: userBillingOidc,
        [HCFA_FIELDS.billingNpi]: npiOidc,
        [HCFA_FIELDS.icdInd]: '0',
        [HCFA_FIELDS.federalTaxId]: taxID,
        [HCFA_FIELDS.HCFA33psysiciancheckbox]: HCFA33PhysicianEnum.supplier,
        [HCFA_FIELDS.billingCity]: providerCityOidc,
        [HCFA_FIELDS.billingState]: providerStateOidc,
        [HCFA_FIELDS.billingZip]: providerZipCodeOidc,
        [HCFA_FIELDS.HCFA33Address2]: providerAddressOtherOidc
      };

      setData({ ...getFormProvidersDataFromGlobalObject(), ...toSet });
    }
  }, []);

  const copyPatientToInsurance = () => {
    const toSet = {
      // '4_ins_last_name': form?.getFieldValue('2_pat_last_name'),
      // '4_ins_first_name': form?.getFieldValue('2_pat_first_name'),
      // '4_ins_mi': form?.getFieldValue('2_pat_mi'),
      // [HCFA_FIELDS.insuredDateOfBirth]: form?.getFieldValue('3_pat_dob'),
      '7_ins_addr_1': form?.getFieldValue('5_pat_addr_1'),
      '7_ins_city': form?.getFieldValue('5_pat_city'),
      '7_ins_state': form?.getFieldValue('5_pat_state'),
      '7_ins_zip': form?.getFieldValue('5_pat_zip'),
      [HCFA_FIELDS.insuredPhone]: form?.getFieldValue(HCFA_FIELDS.patientPhone),
      [HCFA_FIELDS.ins_addr2]: form?.getFieldValue(HCFA_FIELDS.pat_addr2)
      // [HCFA_FIELDS.insuredSex]: form?.getFieldValue(HCFA_FIELDS.patientSex),
      // [HCFA_FIELDS.insuredGen]: form?.getFieldValue(HCFA_FIELDS.patientGen)
    };
    setData({ ...getFormProvidersDataFromGlobalObject(), ...toSet });
    form?.setFieldsValue(toSet);
  };

  function handleFormChanges(changedValue: HcfaFormValueChange, existingValues: Record<string, any>) {
    setData(existingValues);
    const isFormTouched = form?.isFieldsTouched();
    isFormTouched && setIsFormDirty(isFormTouched);

    // if (!firstValidationPerformed) return;
    const [fieldName] = Object.keys(changedValue);

    if (fieldName === '24Table') {
      const tableChange = changedValue['24Table'] as HcfaTableChange;
      const changedRow = tableChange.findIndex(Boolean);
      if (changedRow === -1) return;
      const [changeFieldName] = Object.keys(tableChange[changedRow] as Record<string, string>);
      if (changeFieldName == HCFA_FIELDS.chargesf24) {
        form?.validateFields([HCFA_FIELDS.totalCharges]);
      }
      if (changeFieldName == '24a_dos_from') {
        form?.validateFields([HCFA_FIELDS.currentIllness, HCFA_FIELDS.otherDate]);
      }
      const tableFieldsToRevalidate = getRevalidateFields(changeFieldName, 'hcfa');

      if (tableFieldsToRevalidate.length)
        revalidateTable({
          row: changedRow,
          fields: tableFieldsToRevalidate,
          formInstance: form!,
          tableName: '24Table'
        });
    } else if (fieldName) {
      if (fieldName === '3_pat_dob') {
        revalidateTable({
          fields: ['24a_dos_from', '24a_dos_to'],
          formInstance: form!,
          tableName: '24Table'
        });
      }
      if (fieldName === HCFA_FIELDS.physicianFirstName || fieldName === HCFA_FIELDS.physicianLastName) {
        revalidateTable({
          fields: [HCFA_FIELDS.renderNPI24],
          formInstance: form!,
          tableName: '24Table'
        });
      }
      if (
        fieldName === HCFA_FIELDS.dxCodeA ||
        fieldName === HCFA_FIELDS.dxCodeB ||
        fieldName === HCFA_FIELDS.dxCodeC ||
        fieldName === HCFA_FIELDS.dxCodeD ||
        fieldName === HCFA_FIELDS.dxCodeE ||
        fieldName === HCFA_FIELDS.dxCodeF ||
        fieldName === HCFA_FIELDS.dxCodeG ||
        fieldName === HCFA_FIELDS.dxCodeH ||
        fieldName === HCFA_FIELDS.dxCodeI ||
        fieldName === HCFA_FIELDS.dxCodeJ ||
        fieldName === HCFA_FIELDS.dxCodeK ||
        fieldName === HCFA_FIELDS.dxCodeL
      ) {
        revalidateTable({
          fields: [HCFA_FIELDS.diagnosisPointer],
          formInstance: form!,
          tableName: '24Table'
        });
      }
      revalidateForm(fieldName, 'hcfa');
    }
  }

  function onLabChange(e: RadioChangeEvent) {
    const outsideLabInput = e.target.value;

    if (outsideLabInput === 'YES') {
      form?.getFieldInstance(HCFA_FIELDS.labCharges)?.focus();
    }
    if (outsideLabInput === 'NO') {
      form?.getFieldInstance(HCFA_FIELDS.icdInd)?.focus();
    }
  }

  usePrompt('You have unsaved changes. Are you sure you want to leave?', isFormDirty);

  const [showOriginalRefInfo, setShowOriginalRefInfo] = React.useState(false);
  const resubmissionCodeInput = Form.useWatch(HCFA_FIELDS.resubmissionCode, form);
  useEffect(() => {
    ['6', '7', '8'].includes(resubmissionCodeInput) ? setShowOriginalRefInfo(true) : setShowOriginalRefInfo(false);
  }, [resubmissionCodeInput]);

  return (
    <>
      <div className="hcfa-form">
        <FormErrorList
          fullWarningInfo={fullWarningInfo}
          tableName={HCFA_FIELDS.servicesTable}
          fullErrorInfo={fullErrorInfo}
          errorInfo={errorInfo}
        />
        <Form
          form={form}
          size="small"
          name="basic"
          layout="vertical"
          scrollToFirstError={false}
          autoComplete="off"
          onValuesChange={handleFormChanges}
          disabled={disabled}
          // onFieldsChange={console.log}
          initialValues={
            isNew
              ? {
                  [HCFA_FIELDS.servicesTable]: [undefined],
                  [HCFA_FIELDS.billingName]: providerNameOidc,
                  [HCFA_FIELDS.billingAddress]: providerAddressOidc,
                  // [HCFA_FIELDS.billingId]: userBillingOidc,
                  [HCFA_FIELDS.billingNpi]: npiOidc,
                  [HCFA_FIELDS.icdInd]: '0',
                  [HCFA_FIELDS.federalTaxId]: taxID,
                  [HCFA_FIELDS.HCFA33psysiciancheckbox]: HCFA33PhysicianEnum.supplier,
                  [HCFA_FIELDS.billingCity]: providerCityOidc,
                  [HCFA_FIELDS.billingState]: providerStateOidc,
                  [HCFA_FIELDS.billingZip]: providerZipCodeOidc,
                  [HCFA_FIELDS.HCFA33Address2]: providerAddressOtherOidc
                }
              : undefined
          }
          // onValuesChange={(values) => {}}
        >
          {disabled && (
            <p
              style={{
                textAlign: 'center',
                opacity: '0.5'
              }}
            >
              <InfoCircleOutlined style={{ marginRight: '7px' }} />
              <i>Read only mode</i>
            </p>
          )}
          <div className="flex inputs-wrapper w80-20 row-1">
            <section className="medicare-wrapper">
              <Row>
                <Col>
                  <Form.Item
                    label={
                      <span>
                        <span className="form_label_color">1.</span>
                        <span> MEDICARE</span>
                      </span>
                    }
                    name={HCFA_FIELDS.insuranceType}
                    rules={[getYupValidator(t, HCFA_FIELDS.insuranceType)]}
                  >
                    <Radio.Group name={HCFA_FIELDS.insuranceType}>
                      <Radio onClick={nullifyRadioGroup} autoFocus value="Medicare">
                        (Medicare #)
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="MEDICAID"
                    name={HCFA_FIELDS.insuranceType}
                    rules={[getYupValidator(t, HCFA_FIELDS.insuranceType)]}
                  >
                    <Radio.Group name={HCFA_FIELDS.insuranceType}>
                      <Radio onClick={nullifyRadioGroup} value={'Medicaid'}>
                        (Medicaid #)
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="TRICARE"
                    name={HCFA_FIELDS.insuranceType}
                    rules={[getYupValidator(t, HCFA_FIELDS.insuranceType)]}
                  >
                    <Radio.Group name={HCFA_FIELDS.insuranceType}>
                      <Radio onClick={nullifyRadioGroup} value={'Tricare'}>
                        (ID# / DoD#)
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="CHAMPVA"
                    name={HCFA_FIELDS.insuranceType}
                    rules={[getYupValidator(t, HCFA_FIELDS.insuranceType)]}
                  >
                    <Radio.Group name={HCFA_FIELDS.insuranceType}>
                      <Radio onClick={nullifyRadioGroup} value={'Champva'}>
                        (Member ID#)
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="GROUP HEALTH PLAN"
                    name={HCFA_FIELDS.insuranceType}
                    rules={[getYupValidator(t, HCFA_FIELDS.insuranceType)]}
                  >
                    <Radio.Group name={HCFA_FIELDS.insuranceType}>
                      <Radio onClick={nullifyRadioGroup} value={'Group'}>
                        (ID#)
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="FECA BLK LUNG"
                    name={HCFA_FIELDS.insuranceType}
                    rules={[getYupValidator(t, HCFA_FIELDS.insuranceType)]}
                  >
                    <Radio.Group name={HCFA_FIELDS.insuranceType}>
                      <Radio onClick={nullifyRadioGroup} value={'Feca'}>
                        (ID#)
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    label="OTHER"
                    name={HCFA_FIELDS.insuranceType}
                    rules={[getYupValidator(t, HCFA_FIELDS.insuranceType)]}
                  >
                    <Radio.Group name={HCFA_FIELDS.insuranceType}>
                      <Radio onClick={nullifyRadioGroup} value={'Other'}>
                        (ID#)
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </section>
            <section>
              <Form.Item
                label={<span className="form_label_color">{'1a. ' + t('common:fields.insured.id')}</span>}
                name={HCFA_FIELDS.insuredId}
                rules={[getYupValidator(t, HCFA_FIELDS.insuredId)]}
              >
                <Input minLength={5} onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
              </Form.Item>
            </section>
          </div>
          <div className="flex inputs-wrapper w35-30-35 row-2">
            <section>
              <div className="ant-form-item-label">
                <label className="form_label_color">2. {t('common:fields.patient.name')}</label>
              </div>
              <FieldsetFullName
                form="hcfa"
                lastNameId={HCFA_FIELDS.patientLastName}
                firstNameId={HCFA_FIELDS.patientFirstName}
                middleInitialId={HCFA_FIELDS.patientMiddleInitial}
                generationId={HCFA_FIELDS.patientGen}
                labels={{
                  middleInitial: t('common:fields.middleNama'),
                  generation: t('common:fields.generation')
                }}
              />
            </section>
            <section>
              <div className="ant-form-item-label">
                <label className="form_label_color">3. {t('common:fields.patient.birthDateSex')}</label>
              </div>
              <div className="flex">
                <FormItemPatientBirthday label={t('common:fields.birthDate')} />
                <FormItemSex id={HCFA_FIELDS.patientSex} />
              </div>
            </section>
            <section>
              <div className="ant-form-item-label">
                <label className="form_label_color">4. {t('common:fields.insured.name')}</label>
              </div>
              <FieldsetFullName
                form="hcfa"
                lastNameId={HCFA_FIELDS.insuredLastName}
                firstNameId={HCFA_FIELDS.insuredFirstName}
                middleInitialId={HCFA_FIELDS.insuredMiddleInitial}
                generationId={HCFA_FIELDS.insuredGen}
                labels={{
                  middleInitial: t('common:fields.middleNama'),
                  generation: t('common:fields.generation')
                }}
              />
            </section>
          </div>
          <div className="flex inputs-wrapper w35-30-35 row-3">
            <section>
              <div className="ant-form-item-label">
                <label className="form_label_color"> 5. PATIENT'S ADDRESS (NO. STREET) </label>
              </div>

              <FieldsetLocation
                form="hcfa"
                ids={{
                  address: HCFA_FIELDS.patientAddress,
                  city: HCFA_FIELDS.patientCity,
                  state: HCFA_FIELDS.patientState,
                  zipcode: HCFA_FIELDS.patientZip,
                  addressTwo: HCFA_FIELDS.pat_addr2,
                  phone: HCFA_FIELDS.patientPhone
                }}
                breakPoints={{
                  address: 12,
                  city: 12,
                  state: 12,
                  zipcode: 12,
                  addressTwo: 12,
                  phone: 24
                }}
                labelColoring={true}
                limitedAddress={true}
                consecutiveAddress={true}
              />
            </section>
            <section className="flex flex-column">
              <FormItemPatientRelationship label={'6.' + t('common:fields.patient.insuredRelation')} />
              <Divider />
              <div className="ant-form-item-label">
                <label className="form_label_color"> 8. {t('common:fields.reservedForNuccUse1')} </label>
              </div>
              <Form.Item
                name={HCFA_FIELDS.reservedForNuccUse1}
                rules={[getYupValidator(t, HCFA_FIELDS.reservedForNuccUse1)]}
              >
                <Input placeholder="NUCC" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
              </Form.Item>
            </section>
            <section>
              <div className="ant-form-item-label">
                <label className="form_label_color"> 7. INSURED'S ADDRESS (NO. STREET) </label>
                <Button
                  variant="outline"
                  color="neutral"
                  onClick={copyPatientToInsurance}
                  type="button"
                  disabled={disabled}
                >
                  Copy Patient data to Insured
                </Button>
              </div>
              <FieldsetLocation
                form="hcfa"
                ids={{
                  address: HCFA_FIELDS.insuredAddress,
                  city: HCFA_FIELDS.insuredCity,
                  state: HCFA_FIELDS.insuredState,
                  zipcode: HCFA_FIELDS.insuredZip,
                  addressTwo: HCFA_FIELDS.ins_addr2,
                  phone: HCFA_FIELDS.insuredPhone
                }}
                labels={{
                  address: '7.' + t('common:fields.insured.address') + ' (' + t('common:fields.streetNumber') + ')'
                }}
                breakPoints={{
                  address: 12,
                  city: 12,
                  state: 12,
                  zipcode: 12,
                  addressTwo: 12,
                  phone: 24
                }}
                limitedAddress={true}
                consecutiveAddress={true}
              />
              {/* <Form.Item
                className="field--first field--last"
                label={t('ub04:form.addressTwo')}
                name={HCFA_FIELDS.ins_addr2}
                rules={[getYupValidator(t, HCFA_FIELDS.ins_addr2)]}
              >
                <Input
                  placeholder="ADDRESS 2"
                  onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                />
              </Form.Item> */}
            </section>
          </div>
          <div className="flex inputs-wrapper w35-30-35 row-4">
            <section>
              <div className="ant-form-item-label">
                <label className="form_label_color"> 9. OTHER INSURED'S POLICY OR GROUP NUMBER </label>
              </div>
              <FieldsetFullName
                form="hcfa"
                lastNameId={HCFA_FIELDS.otherInsuredLastName}
                firstNameId={HCFA_FIELDS.otherInsuredFirstName}
                middleInitialId={HCFA_FIELDS.otherInsuredMiddleInitial}
                generationId={HCFA_FIELDS.otherInsuredGen}
                labels={{
                  middleInitial: t('common:fields.middleNama'),
                  generation: t('common:fields.generation')
                }}
              />
              <Form.Item
                rules={[getYupValidator(t, HCFA_FIELDS.otherInsuredId)]}
                label={'a.' + t('common:fields.otherInsured.policy')}
                name={HCFA_FIELDS.otherInsuredId}
              >
                <Input
                  placeholder="1AB2CD3EF45"
                  onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                />
              </Form.Item>

              <Form.Item
                label={'b.' + t('common:fields.reservedForNuccUse2')}
                name={HCFA_FIELDS.reservedForNuccUse2}
                rules={[getYupValidator(t, HCFA_FIELDS.reservedForNuccUse2)]}
              >
                <Input placeholder="NUCC" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
              </Form.Item>
              <Form.Item
                label={'c.' + t('common:fields.employerName')}
                name={HCFA_FIELDS.reservedForNuccUse3}
                rules={[getYupValidator(t, HCFA_FIELDS.reservedForNuccUse3)]}
              >
                <Input placeholder="NUCC" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
              </Form.Item>

              <Form.Item
                label={'d. ' + t('common:fields.insurancePlanProgramName')}
                name={HCFA_FIELDS.otherInsuredPlanName}
                rules={[getYupValidator(t, HCFA_FIELDS.otherInsuredPlanName)]}
              >
                <Input placeholder="My plan" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
              </Form.Item>
            </section>
            <section className="flex flex-column">
              <div className="ant-form-item-label">
                <label className="form_label_color">10. {t('common:fields.patientCondition')}:</label>
              </div>
              <FormItemEmployment
                label={'a. ' + t('common:fields.employment') + ' (' + t('common:fields.employmentStatus') + ')'}
              />
              <div className="flex">
                <Form.Item label={'b. ' + t('common:fields.autoAccident')} name={HCFA_FIELDS.autoAccident}>
                  <Radio.Group name={HCFA_FIELDS.autoAccident} style={{ maxWidth: '150px' }}>
                    <Radio onClick={nullifyRadioGroup} value={'YES'}>
                      {t('common:yes')}
                    </Radio>
                    <Radio onClick={nullifyRadioGroup} value={'NO'}>
                      {t('common:no')}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  rules={[getYupValidator(t, HCFA_FIELDS.accidentState)]}
                  label={t('common:fields.autoAccidentState')}
                  name={HCFA_FIELDS.accidentState}
                >
                  <SelectState placeholder="STATE" inputKey={HCFA_FIELDS.accidentState} />
                </Form.Item>
              </div>
              <Form.Item label={'c. ' + t('common:fields.otherAccident')} name={HCFA_FIELDS.otherAccident}>
                <Radio.Group name={HCFA_FIELDS.otherAccident} style={{ maxWidth: '150px' }}>
                  <Radio onClick={nullifyRadioGroup} value={'YES'}>
                    {t('common:yes')}
                  </Radio>
                  <Radio onClick={nullifyRadioGroup} value={'NO'}>
                    {t('common:no')}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </section>
            <section>
              <Form.Item
                label={<span className="form_label_color">11. {t('common:fields.insured.policyGroup')}</span>}
                name={HCFA_FIELDS.insuredPolicyGroup}
                rules={[getYupValidator(t, HCFA_FIELDS.insuredPolicyGroup)]}
              >
                <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
              </Form.Item>

              <div className="flex">
                <Form.Item
                  rules={[
                    getYupValidator(t, HCFA_FIELDS.insuredDateOfBirth),
                    getYupWarning(t, HCFA_FIELDS.insuredDateOfBirth)
                  ]}
                  label={'a. ' + t('common:fields.insured.birthDate')}
                  name={HCFA_FIELDS.insuredDateOfBirth}
                >
                  <DatePickerAutoPopulate
                    suffixIcon={null}
                    placeholder={t('common:fields.dateFormat')}
                    panelRender={() => false}
                    format={DATE_FORMAT}
                    name={HCFA_FIELDS.insuredDateOfBirth}
                    form={form!}
                  />
                </Form.Item>
                <Form.Item label="SEX" name={HCFA_FIELDS.insuredSex}>
                  <Radio.Group name={HCFA_FIELDS.insuredSex} style={{ maxWidth: '180px' }}>
                    <Radio onClick={nullifyRadioGroup} value="Male">
                      Male
                    </Radio>
                    <Radio onClick={nullifyRadioGroup} value="Female">
                      Female
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              <div className="flex w-100">
                <Form.Item label={'b. ' + t('common:fields.otherClaimQual')} name={HCFA_FIELDS.otherClaimQuality}>
                  <Select
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      (`${option?.value}` ?? '').toLowerCase().includes(input?.toLowerCase())
                    }
                    onSearch={(input) => {
                      onSearchSelect(form, HCFA_FIELDS.otherClaimQuality, input, CLAIM_QUALITY_CODES);
                    }}
                  >
                    {CLAIM_QUALITY_CODES.map((code) => (
                      <Select.Option key={code} value={code}>
                        {code}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label={'b. ' + t('common:fields.otherClaimId') + ' (' + t('common:fields.designatedByNucc') + ')'}
                  name={HCFA_FIELDS.otherClaimId}
                  rules={[getYupValidator(t, HCFA_FIELDS.otherClaimId)]}
                >
                  <Input
                    placeholder="A123456789"
                    onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                  />
                </Form.Item>
              </div>
              <Form.Item
                label={'c. ' + t('common:fields.insurancePlanProgramName')}
                name={HCFA_FIELDS.insurancePlanProgramName}
                rules={[getYupValidator(t, HCFA_FIELDS.insurancePlanProgramName)]}
              >
                <Input
                  placeholder={t('hcfa:form.placeholders.insurancePlan')}
                  onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                />
              </Form.Item>
              <Form.Item
                help={t('hcfa:form.help.paper.otherBenefitPlan')}
                label={'d. ' + t('common:fields.otherBenefitPlan')}
                name={HCFA_FIELDS.otherCoverage}
                rules={[getYupValidator(t, HCFA_FIELDS.otherCoverage)]}
              >
                <Radio.Group name={HCFA_FIELDS.otherCoverage} style={{ maxWidth: '150px' }}>
                  <Radio onClick={nullifyRadioGroup} value={'YES'}>
                    {t('common:yes')}
                  </Radio>
                  <Radio onClick={nullifyRadioGroup} value={'NO'}>
                    {t('common:no')}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </section>
          </div>
          <div className="flex inputs-wrapper w65-35 row-5">
            <section>
              <div className="ant-form-item-label">
                <label className="form_label_color">12. {t('common:fields.patient.signature')}.</label>
              </div>
              <div className="field-description">{t('hcfa:form.patient.authorization')}</div>
              <div className="flex">
                <Form.Item
                  label={t('common:fields.signed')}
                  name={HCFA_FIELDS.patientSignature}
                  rules={[getYupValidator(t, HCFA_FIELDS.patientSignature)]}
                  initialValue={SIGNATURE_OPTIONS[0].value}
                >
                  <Select
                    showSearch
                    allowClear
                    onSearch={(input) => {
                      onSearchSelect(form, HCFA_FIELDS.patientSignature, input, ['Signed', '']);
                    }}
                    optionFilterProp="key"
                  >
                    {SIGNATURE_OPTIONS.map((code) => (
                      <Select.Option key={code.label} value={code.value}>
                        {code.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={t('common:fields.date.date')}
                  name={HCFA_FIELDS.patientSignedDate}
                  rules={[
                    getYupValidator(t, HCFA_FIELDS.patientSignedDate),
                    getYupWarning(t, HCFA_FIELDS.patientSignedDate)
                  ]}
                >
                  <DatePickerAutoPopulate
                    suffixIcon={null}
                    placeholder={t('common:fields.dateFormat')}
                    panelRender={() => false}
                    format={DATE_FORMAT}
                    name={HCFA_FIELDS.patientSignedDate}
                    form={form!}
                  />
                </Form.Item>
              </div>
            </section>
            <section>
              <div className="ant-form-item-label">
                <label className="form_label_color">13. {t('common:fields.insured.signature')}</label>
              </div>
              <div className="field-description">{t('hcfa:form.insured.authorization')}</div>
              <div className="flex">
                <Form.Item
                  label={t('common:fields.signed')}
                  name={HCFA_FIELDS.insuredSignature}
                  rules={[getYupValidator(t, HCFA_FIELDS.insuredSignature)]}
                  initialValue={SIGNATURE_OPTIONS[0].value}
                >
                  <Select
                    showSearch
                    allowClear
                    onSearch={(input) => {
                      onSearchSelect(form, HCFA_FIELDS.insuredSignature, input, ['Signed', '']);
                    }}
                    optionFilterProp="key"
                  >
                    {SIGNATURE_OPTIONS.map((code) => (
                      <Select.Option key={code.label} value={code.value}>
                        {code.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={t('common:fields.patientAuthSignatureDate')}
                  name={HCFA_FIELDS.insuredSignatureDate}
                  rules={[
                    getYupValidator(t, HCFA_FIELDS.insuredSignatureDate),
                    getYupWarning(t, HCFA_FIELDS.insuredSignatureDate)
                  ]}
                >
                  <DatePickerAutoPopulate
                    suffixIcon={null}
                    placeholder={t('common:fields.dateFormat')}
                    panelRender={() => false}
                    format={DATE_FORMAT}
                    name={HCFA_FIELDS.insuredSignatureDate}
                    form={form!}
                  />
                </Form.Item>
              </div>
            </section>
          </div>
          <div className="flex inputs-wrapper w35-30-35 row-6">
            <section className="flex-align-start">
              <Form.Item
                label={<span className="form_label_color">14. {t('common:fields.date.currentIllness')}</span>}
                rules={[getYupValidator(t, HCFA_FIELDS.currentIllness), getYupWarning(t, HCFA_FIELDS.currentIllness)]}
                name={HCFA_FIELDS.currentIllness}
              >
                <DatePickerAutoPopulate
                  suffixIcon={null}
                  placeholder={t('common:fields.dateFormat')}
                  panelRender={() => false}
                  format={DATE_FORMAT}
                  name={HCFA_FIELDS.currentIllness}
                  form={form!}
                />
              </Form.Item>
              <Form.Item
                rules={[getYupValidator(t, HCFA_FIELDS.quality)]}
                label={t('common:fields.qual')}
                name={HCFA_FIELDS.quality}
              >
                <Select
                  showSearch
                  filterOption={(input, option) =>
                    (`${option?.value}` ?? '').toLowerCase().includes(input?.toLowerCase())
                  }
                  onSearch={(input) => {
                    onSearchSelect(form, HCFA_FIELDS.quality, input, QUALITY_CODES);
                  }}
                  allowClear
                >
                  {QUALITY_CODES.map((code) => (
                    <Select.Option key={code} value={code}>
                      {code}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </section>
            <section className="flex-align-start">
              <Form.Item
                label={<span className="form_label_color">15. {t('common:fields.date.other')}</span>}
                rules={[getYupValidator(t, HCFA_FIELDS.otherDate), getYupWarning(t, HCFA_FIELDS.otherDate)]}
                name={HCFA_FIELDS.otherDate}
              >
                <DatePickerAutoPopulate
                  suffixIcon={null}
                  placeholder={t('common:fields.dateFormat')}
                  panelRender={() => false}
                  format={DATE_FORMAT}
                  name={HCFA_FIELDS.otherDate}
                  form={form!}
                />
              </Form.Item>
              <Form.Item
                rules={[getYupValidator(t, HCFA_FIELDS.otherQuality)]}
                label={t('common:fields.qual')}
                name={HCFA_FIELDS.otherQuality}
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    (`${option?.value}` ?? '').toLowerCase().includes(input?.toLowerCase())
                  }
                  onSearch={(input) => {
                    onSearchSelect(form, HCFA_FIELDS.otherQuality, input, OTHER_QUALITY_CODES_HCFA);
                  }}
                >
                  {OTHER_QUALITY_CODES_HCFA.map((code) => (
                    <Select.Option key={code} value={code}>
                      {code}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </section>
            <section>
              <div className="ant-form-item-label">
                <label className="form_label_color">16. {t('common:fields.date.unableToWork')}</label>
              </div>
              <Form.Item
                rules={[
                  getYupValidator(t, HCFA_FIELDS.unableToWorkFromDate),
                  getYupWarning(t, HCFA_FIELDS.unableToWorkFromDate)
                ]}
                label={t('common:from')}
                name={HCFA_FIELDS.unableToWorkFromDate}
              >
                <DatePickerAutoPopulate
                  suffixIcon={null}
                  placeholder={t('common:fields.dateFormat')}
                  panelRender={() => false}
                  format={DATE_FORMAT}
                  name={HCFA_FIELDS.unableToWorkFromDate}
                  form={form!}
                />
              </Form.Item>
              <Form.Item
                rules={[
                  getYupValidator(t, HCFA_FIELDS.unableToWorkToDate),
                  getYupWarning(t, HCFA_FIELDS.unableToWorkToDate)
                ]}
                label={t('common:to')}
                name={HCFA_FIELDS.unableToWorkToDate}
              >
                <DatePickerAutoPopulate
                  suffixIcon={null}
                  placeholder={t('common:fields.dateFormat')}
                  panelRender={() => false}
                  format={DATE_FORMAT}
                  name={HCFA_FIELDS.unableToWorkToDate}
                  form={form!}
                />
              </Form.Item>
            </section>
          </div>
          <div className="flex inputs-wrapper w35-30-35 row-7">
            <section>
              <div className="ant-form-item-label">
                <label className="form_label_color">17. {t('common:fields.referringProvider.physician')}</label>
              </div>
              <Row gutter={0} style={{ width: '100%' }}>
                <Col span={12}>
                  <Form.Item
                    label={t('common:fields.referringProvider.qual')}
                    name={HCFA_FIELDS.referringProviderQuality}
                  >
                    <Select
                      maxLength={2}
                      allowClear
                      showSearch
                      filterOption={(input, option) =>
                        (`${option?.value}` ?? '').toLowerCase().includes(input?.toLowerCase())
                      }
                      onSearch={(input) => {
                        onSearchSelect(
                          form,
                          HCFA_FIELDS.referringProviderQuality,
                          input,
                          REFERRING_PROVIDER_QUALITY_CODES
                        );
                      }}
                    >
                      {REFERRING_PROVIDER_QUALITY_CODES.map((code) => (
                        <Select.Option key={code} value={code}>
                          {code}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={t('common:fields.title')} name={HCFA_FIELDS.referringTitle}>
                    <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                  </Form.Item>
                </Col>
              </Row>
              <FieldsetFullName
                form="hcfa"
                lastNameId={HCFA_FIELDS.referringProviderLastName}
                firstNameId={HCFA_FIELDS.referringProviderFirstName}
                middleInitialId={HCFA_FIELDS.referringProviderMiddleInitial}
                generationId={HCFA_FIELDS.referringProviderGen}
                labels={{
                  middleInitial: t('common:fields.middleNama'),
                  generation: t('common:fields.generation')
                }}
              />
            </section>
            <section>
              <Form.Item
                label={<span className="form_label_color">{'17a-1. ' + t('common:fields.referringProvider.id')}</span>}
                name={HCFA_FIELDS.referringProviderId}
                rules={[getYupValidator(t, HCFA_FIELDS.referringProviderId)]}
              >
                <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
              </Form.Item>
              <Form.Item
                label={<span className="form_label_color">{'17a-2. ' + t('common:fields.idProviderQual')}</span>}
                name={HCFA_FIELDS.referringProviderIdQuality}
              >
                <Select
                  maxLength={2}
                  allowClear
                  showSearch
                  options={REFERRING_PROVIDER_ID_QUALITY_CODES.map((el) => ({ value: el, label: el }))}
                  onSearch={(input) => {
                    onSearchSelect(
                      form,
                      HCFA_FIELDS.referringProviderIdQuality,
                      input,
                      REFERRING_PROVIDER_ID_QUALITY_CODES
                    );
                  }}
                />
              </Form.Item>
              <Form.Item
                rules={[getYupValidator(t, HCFA_FIELDS.referringNpi)]}
                label={<span className="form_label_color">{'17b. ' + t('common:fields.npi')}</span>}
                name={HCFA_FIELDS.referringNpi}
              >
                <Input onInput={toPositiveNumber} placeholder="1234567890" />
              </Form.Item>
            </section>
            <section>
              <div className="ant-form-item-label">
                <label className="form_label_color">18. {t('common:fields.date.hospitalization')}</label>
              </div>
              <Form.Item
                rules={[
                  getYupValidator(t, HCFA_FIELDS.hospitalizationFromDate),
                  getYupWarning(t, HCFA_FIELDS.hospitalizationFromDate)
                ]}
                label={t('common:from')}
                name={HCFA_FIELDS.hospitalizationFromDate}
              >
                <DatePickerAutoPopulate
                  suffixIcon={null}
                  placeholder={t('common:fields.dateFormat')}
                  panelRender={() => false}
                  format={DATE_FORMAT}
                  name={HCFA_FIELDS.hospitalizationFromDate}
                  form={form!}
                />
              </Form.Item>
              <Form.Item
                rules={[
                  getYupValidator(t, HCFA_FIELDS.hospitalizationToDate),
                  getYupWarning(t, HCFA_FIELDS.hospitalizationToDate)
                ]}
                label={t('common:to')}
                name={HCFA_FIELDS.hospitalizationToDate}
              >
                <DatePickerAutoPopulate
                  suffixIcon={null}
                  placeholder={t('common:fields.dateFormat')}
                  panelRender={() => false}
                  format={DATE_FORMAT}
                  name={HCFA_FIELDS.hospitalizationToDate}
                  form={form!}
                />
              </Form.Item>
            </section>
          </div>
          <div className="flex inputs-wrapper w65-35 row-8">
            <section>
              <Form.Item
                label={
                  <span className="form_label_color">
                    19. {t('common:fields.additionalClaimInfo')} ({t('common:fields.designatedByNucc')})
                  </span>
                }
                name={HCFA_FIELDS.additionalClaim19}
                rules={[getYupValidator(t, HCFA_FIELDS.additionalClaim19)]}
              >
                <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
              </Form.Item>
            </section>
            <section className="flex">
              <Form.Item
                label={<span className="form_label_color">20. {t('common:fields.outsideLab')}</span>}
                name={HCFA_FIELDS.outsideLab}
              >
                <Radio.Group onChange={onLabChange} name={HCFA_FIELDS.outsideLab}>
                  <Radio onClick={nullifyRadioGroup} value={'YES'}>
                    {t('common:yes')}
                  </Radio>
                  <Radio onClick={nullifyRadioGroup} value={'NO'}>
                    {t('common:no')}
                  </Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                rules={[getYupValidator(t, HCFA_FIELDS.labCharges)]}
                label={t('common:fields.charges')}
                name={HCFA_FIELDS.labCharges}
              >
                <Input onPaste={toCurrencyPaste} onInput={toCurrency} allowClear prefix="$" style={{ width: '100%' }} />
              </Form.Item>
            </section>
          </div>
          <div className="flex inputs-wrapper w65-35 row-9">
            <section>
              <div className="ant-form-item-label">
                <label className="form_label_color">21. {t('common:fields.diagnosisCodes')}</label>
              </div>
              <div className="field-description">{t('hcfa:form.help.paper.diagnosisCodes')}</div>
              <Form.Item
                label={t('common:fields.icdInd')}
                name={HCFA_FIELDS.icdInd}
                rules={[getYupValidator(t, HCFA_FIELDS.icdInd)]}
                style={{ width: '25%' }}
                initialValue={DIAGNOSIS_CODES[0]}
              >
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    (`${option?.value}` ?? '').toLowerCase().includes(input?.toLowerCase())
                  }
                  onSearch={(input) => {
                    onSearchSelect(form, HCFA_FIELDS.icdInd, input, DIAGNOSIS_CODES);
                  }}
                >
                  {DIAGNOSIS_CODES.map((code) => (
                    <Select.Option key={code} value={code}>
                      {code}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Row>
                {Object.entries(DIAGNOSIS_CODES_MAP).map(([code, fieldName], index) => {
                  return (
                    <Col key={code} span={6}>
                      <Form.Item
                        label={`${code.toUpperCase()}.`}
                        name={fieldName}
                        required={index === 0}
                        rules={
                          index === 0
                            ? [getYupValidator(t, HCFA_FIELDS.dxCodeA), getYupWarning(t, HCFA_FIELDS.dxCodeA)]
                            : [getYupValidator(t, fieldName), getYupWarning(t, fieldName)]
                        }
                      >
                        <Input placeholder="A123" onInput={toAlphaNumDotUppercase} />
                      </Form.Item>
                    </Col>
                  );
                })}
              </Row>
            </section>
            <section>
              <div>
                <div className="ant-form-item-label">
                  <label className="form_label_color">22. Resubmission</label>
                </div>
                <div className="flex">
                  <Form.Item label={<span> Code</span>} name={HCFA_FIELDS.resubmissionCode}>
                    <Select
                      allowClear
                      showSearch
                      options={RESUBMISSION_CODES_HCFA.map((el) => ({ label: el, value: el }))}
                      onSearch={(input) => {
                        onSearchSelect(form, HCFA_FIELDS.resubmissionCode, input, RESUBMISSION_CODES_HCFA);
                      }}
                    />
                  </Form.Item>
                  <div style={{ width: '100%' }}>
                    <Form.Item
                      label={t('common:fields.originalRefNumber')}
                      name={HCFA_FIELDS.originalRefNumber}
                      rules={[getYupValidator(t, HCFA_FIELDS.originalRefNumber)]}
                    >
                      <Input
                        placeholder="AAA123456789"
                        onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                        suffix={
                          <Tooltip title={t('dental:originalRefInfo')}>
                            {showOriginalRefInfo && <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
                          </Tooltip>
                        }
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <Divider />
              {/* <Form.Item label="Number type" name={HCFA_FIELDS.numberType}>
                <Select
                  style={{
                    marginBottom: '0',
                    marginTop: '1rem',
                    marginLeft: '0.5rem'
                  }}
                  placeholder={'Choose type of Number'}
                  options={[
                    {
                      value: HCFA23NumberType.cliaNumber,
                      label: 'CLIA Number'
                    },
                    {
                      value: HCFA23NumberType.zipCode,
                      label: 'Zip Code'
                    }
                  ]}
                  showSearch
                  onSearch={(input) => {
                    onSearchSelect(form, HCFA_FIELDS.numberType, input, [
                      HCFA23NumberType.cliaNumber,
                      HCFA23NumberType.zipCode
                    ]);
                  }}
                />
              </Form.Item> */}
              <Form.Item
                label={<span className="form_label_color">23. {t('common:fields.priorAuthorizationNumber')}</span>}
                name={HCFA_FIELDS.authorizationNumber}
                style={{ marginTop: '5px' }}
                rules={[getYupValidator(t, HCFA_FIELDS.authorizationNumber)]}
              >
                <Input
                  type="text"
                  placeholder="123456789012"
                  onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                />
              </Form.Item>
            </section>
          </div>
          <div className="flex inputs-wrapper table-wrapper row-10">
            <section style={{ overflowX: 'auto' }}>
              <ServicesFieldsList readOnly={disabled} setDisabledSwitch={setDisabledSwitch} />
            </section>
          </div>
          <div className="flex inputs-wrapper w30-15-20-35 row-11">
            <section className="flex-column" style={{ flexDirection: 'row', display: 'flex', width: '32.5%' }}>
              <Form.Item
                label={<span className="form_label_color">25. {t('common:fields.federalTaxId')}</span>}
                name={HCFA_FIELDS.federalTaxId}
                rules={[getYupValidator(t, HCFA_FIELDS.federalTaxId)]}
                required
              >
                <Input onInput={toPositiveNumber} placeholder="123456789" />
              </Form.Item>
              <Form.Item name={HCFA_FIELDS.federalTaxIdType} label={<span className="form_label_color"></span>}>
                <Radio.Group name={HCFA_FIELDS.federalTaxIdType} defaultValue={'EIN'}>
                  <Radio onClick={nullifyRadioGroup} value={'SSN'} style={{ flexWrap: 'nowrap' }}>
                    {t('common:fields.ssn')}
                  </Radio>
                  <Radio onClick={nullifyRadioGroup} value={'EIN'} style={{ flexWrap: 'nowrap' }}>
                    {t('common:fields.ein')}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </section>
            <section style={{ width: '16.25%' }}>
              <div className="ant-form-item-label">
                <label className="form_label_color">26. {t('common:fields.patient.account')}</label>
              </div>
              <Form.Item
                name={HCFA_FIELDS.patientAccountNumber}
                rules={[getYupValidator(t, HCFA_FIELDS.patientAccountNumber)]}
              >
                <Input
                  placeholder="Abcde-A123456"
                  onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                />
              </Form.Item>
            </section>
            <section style={{ width: '16.25%' }}>
              <div className="ant-form-item-label">
                <label className="form_label_color">27. {t('common:fields:acceptAssignment')}</label>
              </div>
              <Form.Item name={HCFA_FIELDS.assignment}>
                <Radio.Group name={HCFA_FIELDS.assignment} style={{ maxWidth: '150px' }}>
                  <Radio onClick={nullifyRadioGroup} value={'YES'}>
                    {t('common:yes')}
                  </Radio>
                  <Radio onClick={nullifyRadioGroup} value={'NO'}>
                    {t('common:no')}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </section>
            <span className="flex w-35">
              <section style={{ height: '100%' }}>
                <div className="ant-form-item-label">
                  <label className="form_label_color">28. {t('common:fields.totalCharge')}</label>
                </div>
                <Form.Item name={HCFA_FIELDS.totalCharges} rules={[getYupValidator(t, HCFA_FIELDS.totalCharges)]}>
                  <Input
                    onPaste={toCurrencyPaste}
                    onInput={toCurrency}
                    allowClear
                    prefix="$"
                    placeholder="50.00"
                    readOnly
                  />
                </Form.Item>
              </section>
              <section style={{ height: '100%' }}>
                <div className="ant-form-item-label">
                  <label className="form_label_color">29. {t('common:fields.amountPaid')}</label>
                </div>
                <Form.Item
                  name={HCFA_FIELDS.amountPaid}
                  rules={[getYupValidator(t, HCFA_FIELDS.amountPaid), getYupWarning(t, HCFA_FIELDS.amountPaid)]}
                >
                  <Input allowClear onPaste={toCurrencyPaste} onInput={toCurrency} prefix="$" placeholder="50.00" />
                </Form.Item>
              </section>
              <section style={{ height: '100%' }}>
                <div className="ant-form-item-label">
                  <label className="form_label_color">30. {t('common:fields.rsvdForNuccUse')}</label>
                </div>
                <Form.Item name={HCFA_FIELDS.rsvdForNuccUse} rules={[getYupValidator(t, HCFA_FIELDS.rsvdForNuccUse)]}>
                  <Input placeholder="NUCC" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                </Form.Item>
              </section>
            </span>
          </div>
          <div className="flex inputs-wrapper w30-30-35 row-12">
            <section className="flex flex-column">
              <div className="column_direction_divs">
                <div className="flex-column">
                  <div className="ant-form-item-label">
                    <label className="form_label_color">31.{t('common:fields.physicianSignature')}</label>
                  </div>
                  <small>{t('common:fields.ifCertify')}</small>
                  {/* <Form.Item name={HCFA_FIELDS.HCFA31psysiciancheckbox}>
                    <Radio.Group className="psysician-checkbox">
                      <Radio value={'supplier'}>Supplier</Radio>
                      <Radio value={'physician'}>Physician</Radio>
                    </Radio.Group>
                  </Form.Item> */}

                  <FieldsetFullName
                    form="hcfa"
                    lastNameId={HCFA_FIELDS.physicianLastName}
                    firstNameId={HCFA_FIELDS.physicianFirstName}
                    middleInitialId={HCFA_FIELDS.physicianMiddleInitial}
                    // suffixId={HCFA_FIELDS.physicianSuffix}
                    generationId={HCFA_FIELDS.physicianGeneration}
                    labels={{
                      middleInitial: t('common:fields.middleNama'),
                      generation: t('common:fields.generation')
                    }}
                  />
                </div>

                <div className="flex">
                  <Form.Item
                    label={t('Credentials')}
                    name={HCFA_FIELDS.physicianCredential}
                    rules={[getYupValidator(t, HCFA_FIELDS.physicianCredential)]}
                  >
                    <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                  </Form.Item>
                  <Form.Item
                    label={t('common:fields.date.date')}
                    name={HCFA_FIELDS.signatureOfPhysicianDate}
                    rules={[
                      getYupValidator(t, HCFA_FIELDS.signatureOfPhysicianDate),
                      getYupWarning(t, HCFA_FIELDS.signatureOfPhysicianDate)
                    ]}
                  >
                    <DatePickerAutoPopulate
                      suffixIcon={null}
                      placeholder={t('common:fields.dateFormat')}
                      panelRender={() => false}
                      format={DATE_FORMAT}
                      name={HCFA_FIELDS.signatureOfPhysicianDate}
                      form={form!}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('common:fields.signed')}
                    name={HCFA_FIELDS.signatureOfPhysician}
                    rules={[getYupValidator(t, HCFA_FIELDS.signatureOfPhysician)]}
                  >
                    <Select
                      showSearch
                      allowClear
                      onSearch={(input) => {
                        onSearchSelect(form, HCFA_FIELDS.signatureOfPhysician, input, ['Signed', '']);
                      }}
                      optionFilterProp="key"
                    >
                      {SIGNATURE_OPTIONS.map((code) => (
                        <Select.Option key={code.label} value={code.value}>
                          {code.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </section>
            <section className="flex flex-column">
              <div
                style={{
                  marginBottom: '0.5rem',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Form.Item valuePropName="checked" name={HCFA_FIELDS.isAmbulance} style={{ width: 'auto' }}>
                  <Switch
                    tabIndex={disabledSwitch ? -1 : 0}
                    style={{ pointerEvents: disabledSwitch ? 'none' : 'all' }}
                    size="small"
                  />
                </Form.Item>
                <span style={{ paddingTop: '2px' }}> Is Ambulance?</span>
              </div>
              {isAmbulance ? (
                <div className="column_direction_divs">
                  <div className="flex-column">
                    <div className="ant-form-item-label">
                      <label className="form_label_color">32. LOCATION</label>
                    </div>
                    <div>
                      <SendOutlined /> FROM
                    </div>
                    <div>
                      <Form.Item
                        label={`Amb From Location`}
                        name={HCFA_FIELDS.hcfaAmbFromLocation}
                        className="mb-0"
                        rules={[getYupValidator(t, HCFA_FIELDS.hcfaAmbFromLocation)]}
                        // rules={[getYupValidatorServiceTable(t, HCFA_FIELDS.renderNPI24)]}
                      >
                        <Input
                          onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                          placeholder="Location"
                        />
                      </Form.Item>
                      <FieldsetLocation
                        form="hcfa"
                        ids={{
                          address: HCFA_FIELDS.hcfaFromAddress,
                          city: HCFA_FIELDS.hcfaFromCity,
                          state: HCFA_FIELDS.hcfaFromState,
                          zipcode: HCFA_FIELDS.hcfaFromZip,
                          addressTwo: HCFA_FIELDS.box32FromFacilityAddress2
                        }}
                        breakPoints={{
                          address: 12,
                          city: 12,
                          state: 12,
                          zipcode: 12,
                          addressTwo: 12
                        }}
                        consecutiveAddress={true}
                      />
                    </div>
                    <div className="ambulance">
                      <h3>
                        <FlagOutlined /> DESTINATION
                      </h3>
                      <Form.Item
                        // {...field}
                        label={`Amb To Location`}
                        name={HCFA_FIELDS.hcfaAmbToLocation}
                        rules={[getYupValidator(t, HCFA_FIELDS.hcfaAmbToLocation)]}
                        //rules={[getYupValidatorServiceTable(t, HCFA_FIELDS.renderNPI24)]}
                      >
                        <Input
                          onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                          placeholder="Location"
                        />
                      </Form.Item>
                      <FieldsetLocation
                        form="hcfa"
                        ids={{
                          address: HCFA_FIELDS.hcfaToAddress,
                          city: HCFA_FIELDS.hcfaToCity,
                          state: HCFA_FIELDS.hcfaToState,
                          zipcode: HCFA_FIELDS.hcfaToZip,
                          addressTwo: HCFA_FIELDS.box32ToFacilityAddress2
                        }}
                        breakPoints={{
                          address: 12,
                          city: 12,
                          state: 12,
                          zipcode: 12,
                          addressTwo: 12
                        }}
                        consecutiveAddress={true}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="column_direction_divs">
                  <div className="flex-column">
                    <div className="ant-form-item-label">
                      <label className="form_label_color">32. {t('common:fields.serviceFacilityInformation')}</label>
                    </div>
                    <Form.Item
                      label={t('common:name')}
                      name={HCFA_FIELDS.facilityName}
                      rules={[getYupValidator(t, HCFA_FIELDS.facilityName)]}
                    >
                      <Input
                        placeholder={'FULL NAME'}
                        onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                      />
                    </Form.Item>
                    <FieldsetLocation
                      form="hcfa"
                      ids={{
                        address: HCFA_FIELDS.facilityAddress,
                        city: HCFA_FIELDS.facilityCity,
                        state: HCFA_FIELDS.facilityState,
                        zipcode: HCFA_FIELDS.facilityZip,
                        addressTwo: HCFA_FIELDS.box32Address2
                      }}
                      breakPoints={{
                        city: 12,
                        state: 12,
                        zipcode: 12,
                        phone: 12,
                        address: 12,
                        addressTwo: 12
                      }}
                      consecutiveAddress={true}
                    />
                  </div>
                  <div className="flex w-100">
                    <Form.Item
                      label={'a. ' + t('common:fields.npi')}
                      name={HCFA_FIELDS.facilityNpi}
                      rules={[getYupValidator(t, HCFA_FIELDS.facilityNpi)]}
                    >
                      <Input onInput={toPositiveNumber} placeholder="123456789" />
                    </Form.Item>
                    <Form.Item
                      rules={[getYupValidator(t, HCFA_FIELDS.facilityIdQuality)]}
                      label={'b. ' + t('common:fields.idQual')}
                      name={HCFA_FIELDS.facilityIdQuality}
                    >
                      <Select
                        maxLength={2}
                        allowClear
                        showSearch
                        filterOption={(input, option) =>
                          (`${option?.value}` ?? '').toLowerCase().includes(input?.toLowerCase())
                        }
                        onSearch={(input) => {
                          onSearchSelect(form, HCFA_FIELDS.facilityIdQuality, input, FACILITY_ID_QUALITY_CODES_HCFA);
                        }}
                      >
                        {FACILITY_ID_QUALITY_CODES_HCFA.map((code) => (
                          <Select.Option key={code} value={code}>
                            {code}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      rules={[getYupValidator(t, HCFA_FIELDS.facilityId)]}
                      label={'b. ' + t('common:id')}
                      name={HCFA_FIELDS.facilityId}
                    >
                      <Input
                        placeholder="123456789012"
                        onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                      />
                    </Form.Item>
                  </div>
                </div>
              )}
            </section>
            <section className="flex flex-column">
              <div className="ant-form-item-label">
                <label className="form_label_color">33. {t('common:fields.billingProviderInformation')}</label>
              </div>
              <Form.Item name={HCFA_FIELDS.HCFA33psysiciancheckbox}>
                <Radio.Group name={HCFA_FIELDS.HCFA33psysiciancheckbox} className="psysician-checkbox">
                  <Radio onClick={nullifyRadioGroup} value={HCFA33PhysicianEnum.supplier}>
                    Supplier
                  </Radio>
                  <Radio onClick={nullifyRadioGroup} value={HCFA33PhysicianEnum.physician}>
                    Physician
                  </Radio>
                </Radio.Group>
              </Form.Item>

              {physicianValue33 === HCFA33PhysicianEnum.physician && (
                <FieldsetFullName
                  form="hcfa"
                  lastNameId={HCFA_FIELDS.providersPhysicianLastname}
                  firstNameId={HCFA_FIELDS.providersPhysicianFirstname}
                  middleInitialId={HCFA_FIELDS.providersPhysicianMiddleIntial}
                  credentialsId={HCFA_FIELDS.HCFA33Credentials}
                  generationId={HCFA_FIELDS.HCFA33Generation}
                  labels={{
                    middleInitial: t('common:fields.middleNama'),
                    generation: t('common:fields.generation')
                  }}
                  breakPoints={{ generation: 4, credential: 8 }}
                />
              )}
              {physicianValue33 === HCFA33PhysicianEnum.supplier && (
                <Form.Item
                  label={t('common:name')}
                  name={HCFA_FIELDS.billingName}
                  rules={[getYupValidator(t, HCFA_FIELDS.billingName)]}
                >
                  <Input
                    placeholder={'FULL NAME'}
                    onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                  />
                </Form.Item>
              )}

              <FieldsetLocation
                form="hcfa"
                ids={{
                  address: HCFA_FIELDS.billingAddress,
                  city: HCFA_FIELDS.billingCity,
                  state: HCFA_FIELDS.billingState,
                  zipcode: HCFA_FIELDS.billingZip,
                  addressTwo: HCFA_FIELDS.HCFA33Address2,
                  phone: HCFA_FIELDS.billingPhone
                }}
                breakPoints={{
                  address: 12,
                  city: 12,
                  state: 12,
                  zipcode: 12,
                  addressTwo: 12,
                  phone: 24
                }}
                consecutiveAddress={true}
              />

              <div className="flex w-100">
                <Form.Item
                  label={'a. ' + t('common:fields.npi')}
                  name={HCFA_FIELDS.billingNpi}
                  rules={[getYupValidator(t, HCFA_FIELDS.billingNpi)]}
                >
                  <Input onInput={toPositiveNumber} placeholder="123456789" />
                </Form.Item>
                <Form.Item
                  rules={[getYupValidator(t, HCFA_FIELDS.billingIdQuality)]}
                  label={'b. ' + t('common:fields.idQual')}
                  name={HCFA_FIELDS.billingIdQuality}
                >
                  <Select
                    maxLength={2}
                    allowClear
                    showSearch
                    filterOption={(input, option) =>
                      (`${option?.value}` ?? '').toLowerCase().includes(input?.toLowerCase())
                    }
                    onSearch={(input) => {
                      onSearchSelect(form, HCFA_FIELDS.billingIdQuality, input, BILLING_PROVIDER_ID_QUALITY_CODES_HCFA);
                    }}
                  >
                    {BILLING_PROVIDER_ID_QUALITY_CODES_HCFA.map((code) => (
                      <Select.Option key={code} value={code}>
                        {code}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={'b. ' + t('common:id')}
                  name={HCFA_FIELDS.billingId}
                  rules={[getYupValidator(t, HCFA_FIELDS.billingId)]}
                >
                  <Input
                    placeholder="1234567890"
                    onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                  />
                </Form.Item>
              </div>
            </section>
          </div>
        </Form>
      </div>
    </>
  );
}

export { PaperForm };
