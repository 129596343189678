import React, { useState } from 'react';
import { Button, ButtonList } from '@datum-components/react-components';
import { useTranslation } from 'react-i18next';
import { dentalFormGroups, hcfaFormGroups, ub04FormGroups } from 'config/search';
import { Checkbox, Divider, Modal, Radio, RadioChangeEvent, notification } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { WiMoonFull } from 'react-icons/wi';
import { FaCircleHalfStroke } from 'react-icons/fa6';
import { ReactComponent as CheckIcon } from 'heroicons/outline/check.svg';
import { ReactComponent as ClearIcon } from '@datum-icons/icons/custom/eraser.svg';
import { useFormProvider } from 'context/form.context';
import { FORM_ACTIONS, SECTIONS } from 'config/constants';
import { ClaimStatusEnum } from 'models/enums/ClaimStatusEnum';
import { useNavigate } from 'react-router-dom';
import { copyClaim, copyClaimStraightThrough, getClaimdataById } from 'api/claims';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { type } from '@testing-library/user-event/dist/type';
import { HiOutlineDocumentRemove, HiOutlineDocumentText } from 'react-icons/hi';
import { serializeFormData } from 'utils/common';
import { getClaimDataBySteps } from 'utils/search/search';
import { ClaimDTO } from 'models/DTO/ClaimDTO';
import uuid from 'react-uuid';
import { mapFormValuesToClaimData } from 'helpers/mapFormValuesToClaimData';

type CopyModalProps = {
  isOpen: boolean;
  onClose: () => void;
  formModel: SECTIONS;

  claimId: string;
};

type CopyMode = 'Full' | 'Partial' | null;

function CopyModal({ isOpen, onClose, formModel, claimId }: CopyModalProps): JSX.Element {
  const { t } = useTranslation();
  const { accessToken } = useOidcAccessToken();
  const navigate = useNavigate();
  const [checkedList, setCheckedList] = React.useState<CheckboxValueType[]>();
  const [copyInProgress, setCopyInProgress] = useState(false);

  const [copyMode, setCopyMode] = useState<CopyMode>(null);
  const [_, formDispatch] = useFormProvider();

  const FormGroups = React.useMemo((): Record<string, string>[] => {
    switch (formModel) {
      case SECTIONS.HCFA:
        return hcfaFormGroups;
      case SECTIONS.UB04:
        return ub04FormGroups;
      case SECTIONS.DENTAL:
        return dentalFormGroups;
      default: {
        console.error('Pass form model to copy modal!!!');
        return hcfaFormGroups;
      }
    }
  }, [formModel]);

  const CheckboxLabels = React.useMemo((): string[] => {
    return FormGroups.map((a) => a.label);
  }, [FormGroups]);

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
  };

  const onCheckAllChange = (e: boolean) => {
    setCheckedList(e ? CheckboxLabels : []);
  };

  const handleSave = async () => {
    setCopyInProgress(true);
    try {
      const claimToCopy = await getClaimdataById(claimId, formModel, accessToken, false);

      switch (copyMode) {
        case 'Partial': {
          const filters: string[] = [];
          checkedList?.forEach((checkedElement) => {
            FormGroups.forEach((formElement) => {
              if (formElement.label === checkedElement) {
                filters.push(formElement.value);
              }
            });
          });
          // let isAttachmentChecked = filters.includes('ATTACHMENTS_STEP');
          const claimJson = new ClaimDTO(claimId, ClaimStatusEnum.IN_PROGRESS, [
            {
              fields: [],
              tables: [
                {
                  id: uuid(),
                  name: 'SERVICE TABLE',
                  rows: []
                }
              ]
            }
          ]);
          claimJson.documents?.forEach((doc) => (doc.name = formModel));

          const _data = serializeFormData(getClaimDataBySteps(formModel, claimToCopy, filters));
          mapFormValuesToClaimData(_data, formModel, claimJson);
          claimJson.attachments = claimToCopy.attachments;
          claimJson.fileRepresentations = claimToCopy.fileRepresentations;
          const copiedData = await copyClaim(formModel, accessToken, claimJson, false);
          formDispatch({ type: 'SET_FILTERS', filters: filters });
          navigate(`/${formModel}/${FORM_ACTIONS.EDIT}/${copiedData.docId}`);
          onClose();
          return;
        }
        case 'Full': {
          const claimData = await copyClaim(formModel, accessToken, claimToCopy, false);
          formDispatch({ type: 'SET_CLAIM_ID', claimId: claimData.docId });
          navigate(`/${formModel}/${FORM_ACTIONS.EDIT}/${claimData.docId}`);
          onClose();
        }
      }
    } catch (e) {
      notification.error({ message: 'Claim copy failed', description: 'Server error occured' });
    } finally {
      setCopyInProgress(false);
    }
  };

  const onChangeMode = (e: RadioChangeEvent) => {
    setCopyMode(e.target.value);
  };

  return (
    <Modal
      title="Select fields to be copied"
      className="copy-modal-wrapper"
      centered
      open={isOpen}
      onOk={() => handleSave()}
      onCancel={() => onClose()}
      width={675}
      footer={[
        <Button key="back" variant="outline" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          disabled={!copyMode || copyInProgress}
          key="submit"
          variant="primary"
          onClick={handleSave}
          style={{ marginLeft: '1rem' }}
        >
          Proceed
        </Button>
      ]}
    >
      <div className="CopyModal-container">
        <div className="mode-select">
          <Radio.Group name={'copymodal'} onChange={onChangeMode} value={copyMode}>
            <Radio.Button type="link" value={'Full'}>
              <HiOutlineDocumentText size={20} />
              Click for Full Copy
            </Radio.Button>
            <Radio.Button type="link" value={'Partial'}>
              <HiOutlineDocumentRemove size={20} />
              Click for Partial Copy
            </Radio.Button>
          </Radio.Group>
        </div>
        <br />

        {copyMode === 'Partial' && (
          <div className="copy-modal">
            <ButtonList position="left">
              <Button
                type="button"
                variant="outline"
                key="selectAll"
                leftIcon={<CheckIcon />}
                onClick={() => {
                  onCheckAllChange(true);
                }}
              >
                {t('selectAll')}
              </Button>
              <Button
                type="button"
                variant="outline"
                key="deselectAll"
                leftIcon={<ClearIcon />}
                onClick={() => {
                  onCheckAllChange(false);
                }}
              >
                {t('clearSelection')}
              </Button>
            </ButtonList>
            <Checkbox.Group
              options={CheckboxLabels}
              value={checkedList}
              onChange={onChange}
              style={{ marginTop: '1rem' }}
            />
          </div>
        )}
      </div>

      {copyMode === 'Full' && <div style={{ padding: '0 1rem' }}>Claim will be copied with all data. Proceed?</div>}
    </Modal>
  );
}

export { CopyModal };
