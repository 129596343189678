import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Radio, Input, InputNumber, Row, Col, Checkbox, Select, RadioChangeEvent, Tooltip } from 'antd';

import { FORM_ACTIONS, SECTIONS } from '../../config/constants';
import { DATE_FORMAT } from '../../utils/hcfaConstants';
import { DENTAL_FIELDS } from '../../utils/dental/fields';
import FieldsetFullName from '../../components/dental/shared/FieldsetFullName';
import { FormContext } from '../common/FormProvider';
import FormItemSex from './shared/FormItemSex';
import FieldsetLocation from './shared/FieldsetLocation';
import FormErrorList from 'components/hcfa/shared/FormErrorList';
import ServicesFieldsList from './ServicesFieldsList';
import '../forms/paper-form.scss';
import OtherCoverage from './shared/OtherCoverage';
import PatientsRelatopnshipToPartner from './shared/PatientsRelatopnshipToPartner';
import MissingTeethInformation from './shared/MissingTeethInformation';

import { getYupValidator, getYupWarning } from 'utils/dental/validations';
import { useParams, usePrompt } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';
import { RESUBMISSION_CODES_HCFA, SIGNATURE_OPTIONS } from 'utils/hcfa/constants';
import SelectState from 'components/hcfa/shared/SelectState';
import { onSearchSelect } from 'utils/search/search';
import { getRevalidateFields, revalidateTable } from 'helpers/revalidateHcfaTable';
import DatePickerAutoPopulate from 'components/common/DatePickerAutoPopulate';
import { useAccessTokenProvidedInfo } from 'utils/hooks/useAccessTokenProvidedInfo';
import { nullifyRadioGroup } from 'helpers/nullifyRadioGroup';
import { getFormInstanceFromGlobalObject } from 'utils/hcfa/putFormInstanceInGlobalObject';
import { getFormProvidersDataFromGlobalObject } from 'utils/hcfa/putFormProvidersDataInGlobalObject';
import { toPositiveNumber } from 'helpers/toPositiveNumber';
import { toNum } from '../../helpers/toNum';
import { useHotkeys } from 'react-hotkeys-hook';

function PaperForm({
  actions
}: {
  count: any;
  setCount: any;
  dataSource: any;
  setDataSource: any;
  section: SECTIONS;
  actions?: any;
}): JSX.Element {
  const { t } = useTranslation();
  const {
    form,
    // data,
    setData,
    errorInfo,
    setErrorInfo,
    setFullErrorInfo,
    fullErrorInfo,
    isFormDirty,
    setIsFormDirty,
    fullWarningInfo,
    revalidateForm,
    handleQuickSave
  } = useContext(FormContext);

  const [showOriginalRefInfo, setShowOriginalRefInfo] = React.useState(false);
  const { action } = useParams();
  const relValue = Form.useWatch(DENTAL_FIELDS.patientsRelationship2, form);
  const disabled = action === FORM_ACTIONS.VIEW;
  const resubmissionCodeInput = Form.useWatch(DENTAL_FIELDS.resubmissionCode, form);
  const {
    npiOidc,
    providerAddressOidc,
    providerAddressOtherOidc,
    providerCityOidc,
    providerNameOidc,
    providerStateOidc,
    providerZipCodeOidc,
    taxID
  } = useAccessTokenProvidedInfo();
  useEffect(() => {
    setErrorInfo([]);
    setFullErrorInfo([]);
  }, []);

  useHotkeys(
    'ctrl+s, command+s',
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();

      handleQuickSave('dental');
    },
    { enableOnFormTags: true }
  );

  useEffect(() => {
    ['6', '7', '8'].includes(resubmissionCodeInput) ? setShowOriginalRefInfo(true) : setShowOriginalRefInfo(false);
  }, [resubmissionCodeInput]);

  useEffect(() => {
    handleChange();
  }, [relValue]);

  useEffect(() => {
    if (action === FORM_ACTIONS.NEW) {
      const toSet = {
        [DENTAL_FIELDS.billingNameAddress]: providerAddressOidc,
        [DENTAL_FIELDS.billingNpi]: npiOidc,
        [DENTAL_FIELDS.organizationName]: providerNameOidc,
        [DENTAL_FIELDS.billingAddress2]: providerAddressOtherOidc,
        [DENTAL_FIELDS.billingCity]: providerCityOidc,
        [DENTAL_FIELDS.billingState]: providerStateOidc,
        [DENTAL_FIELDS.billingZip]: providerZipCodeOidc,
        [DENTAL_FIELDS.billingSSNorTIN]: taxID
        // [DENTAL_FIELDS.additionalProviderIdDental]: userBillingOidc
      };

      setData({ ...getFormProvidersDataFromGlobalObject(), ...toSet });
    }
  }, []);

  function handleFormChanges(changedValue: any) {
    const isFormTouched = form?.isFieldsTouched();
    isFormTouched && setIsFormDirty(isFormTouched);
    // if (!firstValidationPerformed) return;
    const [fieldName] = Object.keys(changedValue);
    if (fieldName === DENTAL_FIELDS.servicesTable) {
      const tableChange = changedValue[DENTAL_FIELDS.servicesTable];
      const changedRow = tableChange.findIndex(Boolean);
      if (changedRow === -1) return;
      const [changeFieldName] = Object.keys(tableChange[changedRow] as Record<string, string>);
      const tableFieldsToRevalidate = getRevalidateFields(changeFieldName, 'dental');
      if (tableFieldsToRevalidate.length)
        revalidateTable({
          row: changedRow,
          fields: tableFieldsToRevalidate,
          formInstance: form!,
          tableName: '24Table'
        });
    } else if (fieldName) {
      if (fieldName === DENTAL_FIELDS.patDateOfBirth) {
        revalidateTable({
          fields: [DENTAL_FIELDS.procedureDate],
          formInstance: form!,
          tableName: '24Table'
        });
      }
      if (
        fieldName === DENTAL_FIELDS.dxCodeA ||
        fieldName === DENTAL_FIELDS.dxCodeB ||
        fieldName === DENTAL_FIELDS.dxCodeC ||
        fieldName === DENTAL_FIELDS.dxCodeD
      ) {
        revalidateTable({
          fields: [DENTAL_FIELDS.diagPointer],
          formInstance: form!,
          tableName: '24Table'
        });
      }
      revalidateForm(fieldName, 'dental');
    }
  }

  usePrompt('You have unsaved changes. Are you sure you want to leave?', isFormDirty);

  const fieldsToUppercase = [
    DENTAL_FIELDS.predeterminationNo,
    DENTAL_FIELDS.billingNameAddress,
    DENTAL_FIELDS.billingCity,
    DENTAL_FIELDS.billingState,
    DENTAL_FIELDS.companyName,
    DENTAL_FIELDS.companyNameAddress,
    DENTAL_FIELDS.companyCity,
    DENTAL_FIELDS.companyState,
    DENTAL_FIELDS.subscriberLastName,
    DENTAL_FIELDS.subscriberFirstName,
    DENTAL_FIELDS.subscriberMiddle,
    DENTAL_FIELDS.subscriberSuffix,
    DENTAL_FIELDS.dentalBenefitPlanName,
    DENTAL_FIELDS.insCompanyAddress,
    DENTAL_FIELDS.insCompanyCity,
    DENTAL_FIELDS.insCompanyState,
    DENTAL_FIELDS.subLastName,
    DENTAL_FIELDS.subFirstName,
    DENTAL_FIELDS.subMiddle,
    DENTAL_FIELDS.subInitial,
    DENTAL_FIELDS.subSuffix,
    DENTAL_FIELDS.subNameAddress,
    DENTAL_FIELDS.subCity,
    DENTAL_FIELDS.subState,
    DENTAL_FIELDS.patLastName,
    DENTAL_FIELDS.patFirstName,
    DENTAL_FIELDS.patMiddle,
    DENTAL_FIELDS.patSuffix,
    DENTAL_FIELDS.patAddress,
    DENTAL_FIELDS.patCity,
    DENTAL_FIELDS.patState,
    DENTAL_FIELDS.originalRefNumber,
    DENTAL_FIELDS.billingLastName,
    DENTAL_FIELDS.billingFirstName,
    DENTAL_FIELDS.billingMiddleName,
    DENTAL_FIELDS.billingTitle,
    DENTAL_FIELDS.billingNameAddress,
    DENTAL_FIELDS.billingCity,
    DENTAL_FIELDS.billingState,
    DENTAL_FIELDS.billingAddress2,
    DENTAL_FIELDS.treatLastName,
    DENTAL_FIELDS.treatFirstName,
    DENTAL_FIELDS.treatMiddleName,
    DENTAL_FIELDS.treatTitle,
    DENTAL_FIELDS.treatAddress,
    DENTAL_FIELDS.treatCity,
    DENTAL_FIELDS.treatState,
    DENTAL_FIELDS.treatAddress2,
    DENTAL_FIELDS.toothSystem,
    DENTAL_FIELDS.toothSurface,
    DENTAL_FIELDS.diagPointer
  ];

  useEffect(() => {
    const currentValues = form?.getFieldsValue();
    fieldsToUppercase.forEach((field) => {
      currentValues[field] = currentValues[field]?.toUpperCase() || undefined;
    });

    form?.setFieldsValue(currentValues);
  }, [fieldsToUppercase]);

  const handleChange = () => {
    if (relValue === 'self') {
      const toSet = {
        '20_pat_last_name': form?.getFieldValue('12_subscriber_last_name'),
        '20_pat_first_name': form?.getFieldValue('12_subscriber_first_name'),
        '20_pat_mi': form?.getFieldValue('12_subscriber_mi'),
        '20_pat_suffix': form?.getFieldValue('12_subscriber_suffix'),
        '20_pat_name_address': form?.getFieldValue('12_sub_name_address'),
        '20_pat_city': form?.getFieldValue('12_sub_city'),
        '20_pat_state': form?.getFieldValue('12_subscriber_state'),
        '20_pat_zip': form?.getFieldValue('12_sub_zip'),
        '22_pat_sex': form?.getFieldValue('14_sub_sex'),
        '21_pat_dob': form?.getFieldValue('13_sub_dob')
      };

      setData({ ...getFormProvidersDataFromGlobalObject(), ...toSet });
      form?.setFieldsValue({ ...toSet });
    }
  };

  function handle52DentalKeyDown(e: any) {
    if (e.key === 'Tab') {
      e.preventDefault();
      const nextInput = document.getElementById('basic_52_billing_phone');
      if (nextInput) {
        nextInput.focus();
      }
    }
  }

  function handle52ADentalKeyDown(e: any) {
    if (e.key === 'Tab') {
      e.preventDefault();
      const nextInput = document.getElementById('basic_53_treating_signature');
      if (nextInput) {
        nextInput.focus();
      }
    }
  }

  return (
    <>
      <div className="hcfa-form">
        <FormErrorList
          fullWarningInfo={fullWarningInfo}
          tableName={DENTAL_FIELDS.servicesTable}
          fullErrorInfo={fullErrorInfo}
          errorInfo={errorInfo}
        />
        <Form
          form={form}
          size="small"
          name="basic"
          layout="vertical"
          scrollToFirstError={false}
          autoComplete="off"
          onValuesChange={(changedValue, formValues) => {
            setData(formValues);
            handleFormChanges(changedValue);
          }}
          disabled={disabled}
          initialValues={
            action === FORM_ACTIONS.NEW
              ? {
                  [DENTAL_FIELDS.servicesTable]: [undefined],
                  [DENTAL_FIELDS.billingNameAddress]: providerAddressOidc,
                  [DENTAL_FIELDS.billingNpi]: npiOidc,
                  [DENTAL_FIELDS.organizationName]: providerNameOidc,
                  [DENTAL_FIELDS.billingAddress2]: providerAddressOtherOidc,
                  [DENTAL_FIELDS.billingCity]: providerCityOidc,
                  [DENTAL_FIELDS.billingState]: providerStateOidc,
                  [DENTAL_FIELDS.billingZip]: providerZipCodeOidc,
                  [DENTAL_FIELDS.billingSSNorTIN]: taxID
                  // [DENTAL_FIELDS.additionalProviderIdDental]: userBillingOidc
                }
              : undefined
          }
        >
          {disabled && (
            <p
              style={{
                textAlign: 'center',
                opacity: '0.5'
              }}
            >
              <InfoCircleOutlined style={{ marginRight: '7px' }} />
              <i>Read only mode</i>
            </p>
          )}
          <div className="grid-container-dental-5 no-bt">
            <div className="itemDS9 no-padding no-bb">
              <div className="grid-container-dental-6 no-padding no-br">
                <div className="itemDS90 padding-1-5 bold">{'HEADER INFORMATION'}</div>
                <div className="itemDS91">
                  <label className="form_label_color">
                    1. {t('common:fields.typeOfTransaction')} {t('common:fields.markAllApplicableBoxes')}
                  </label>
                  <Row>
                    <Col>
                      <Form.Item
                        name={DENTAL_FIELDS.typeOfTransaction}
                        rules={[getYupValidator(t, DENTAL_FIELDS.typeOfTransaction)]}
                      >
                        <Checkbox.Group>
                          <Checkbox autoFocus value={DENTAL_FIELDS.transactionTypeServices}>
                            {t('common:fields.statementOfActualServices')}
                          </Checkbox>
                          <Checkbox value={DENTAL_FIELDS.transactionTypeRequestForPreauth}>
                            {t('common:fields.requestForPredeterminalion')}
                          </Checkbox>
                          <Checkbox value={DENTAL_FIELDS.transactionTypeEpsdtTitle}>
                            {t('common:fields.epsdtTitleXix')}
                          </Checkbox>
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
                <div className="itemDS92">
                  <label className="form_label_color">2. {t('common:fields.predeterminationPreauthNumber')}</label>
                  <Form.Item
                    name={DENTAL_FIELDS.predeterminationNo}
                    rules={[getYupValidator(t, DENTAL_FIELDS.predeterminationNo)]}
                  >
                    <Input placeholder="" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                  </Form.Item>
                </div>
                <div className="itemDS93 padding-1-5">
                  <label className="bold">{t('common:fields.insuranceCompnayDentalBen')}</label>
                </div>
                <div className="itemDS94">
                  <label className="form_label_color">
                    3.
                    {t('common:fields.companyName')}, {t('common:fields.address')}, {t('common:fields.city')},
                    {t('common:fields.state')}, {t('common:fields.zipCode')},
                  </label>
                  <FieldsetLocation
                    form="dental"
                    ids={{
                      //plan: DENTAL_FIELDS.companyPlan,
                      name: DENTAL_FIELDS.companyName,
                      address: DENTAL_FIELDS.companyNameAddress,
                      city: DENTAL_FIELDS.companyCity,
                      state: DENTAL_FIELDS.companyState,
                      zipcode: DENTAL_FIELDS.companyZipcode
                      // zipcodeext: DENTAL_FIELDS.companyZipcodeExt
                      // phone: DENTAL_FIELDS.patientPhone
                    }}
                    labels={{
                      //plan: t('common:fields.companyPlan'),
                      name: t('common:fields.companyName'),
                      address: t('common:fields.companyNameAddress'),
                      city: t('common:fields.companyCity'),
                      state: t('common:fields.companyState'),
                      zipcode: t('common:fields.companyZipcode')
                      // zipcodeext: t('common:fields.companyZipcodeExt')
                    }}
                    breakPoints={{
                      //plan: 8,
                      name: 4,
                      address: 4,
                      city: 4,
                      state: 4,
                      zipcode: 4
                      // zipcodeext: 4
                      //phone: 6
                    }}
                  />
                </div>
                <div className="itemDS95 padding-1-5">
                  <label>
                    <span className="bold mr-5">
                      <label className="form_label_color">{t('common:fields.otherCoverage')}</label>
                    </span>
                    <span className="font-size-small">{t('common:fields.markApplicableBoxAndCompleteItems')}</span>
                  </label>
                </div>
                <div className="itemDS96">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: ' flex-start'
                    }}
                  >
                    <label className="form_label_color" style={{ width: 'auto' }}>
                      4.
                    </label>
                    <OtherCoverage />
                  </div>
                  <span style={{ opacity: '0.5' }}> (If both, complete fields below for dental only) </span>
                </div>
                <div className="itemDS96a">
                  <label className="form_label_color">5. {t('common:fields.nameOfPolicyholderSubscriber')}</label>
                  <FieldsetFullName
                    form="dental"
                    lastNameId={DENTAL_FIELDS.subscriberLastName}
                    firstNameId={DENTAL_FIELDS.subscriberFirstName}
                    middleMiddleId={DENTAL_FIELDS.subscriberMiddle}
                    suffixId={DENTAL_FIELDS.subscriberSuffix}
                    labels={{
                      lastName: t('common:fields.lastName'),
                      firstName: t('common:fields.firstName')
                    }}
                    breakPoints={{
                      lastName: 6,
                      firstName: 6,
                      middleInitial: 6,
                      suffix: 6
                    }}
                  />
                </div>
                <div className="itemDS97">
                  <label className="form_label_color">6. {t('common:fields.dateOfBirth')}</label>
                  <Form.Item
                    name={DENTAL_FIELDS.subscriberDateOfBirth}
                    //label={t('Subscriber Date of Birth')}
                    rules={[
                      getYupValidator(t, DENTAL_FIELDS.subscriberDateOfBirth),
                      getYupWarning(t, DENTAL_FIELDS.subscriberDateOfBirth)
                    ]}
                  >
                    <DatePickerAutoPopulate
                      suffixIcon={null}
                      panelRender={() => false}
                      format={DATE_FORMAT}
                      placeholder={t('common:fields.dateFormat')}
                      style={{ width: '100%' }}
                      form={form!}
                      name={DENTAL_FIELDS.subscriberDateOfBirth}
                    />
                  </Form.Item>
                </div>
                <div className="itemDS98">
                  <label className="form_label_color">7. {t('common:fields.gender')}</label>
                  <FormItemSex id={DENTAL_FIELDS.subscriberGender} hideLabel={true} />
                </div>
                <div className="itemDS99">
                  <label className="form_label_color">8. {t('common:fields.policyholderSubscriberID')}</label>
                  <Form.Item
                    name={DENTAL_FIELDS.subscriberNumber}
                    rules={[getYupValidator(t, DENTAL_FIELDS.subscriberNumber)]}
                  >
                    <Input
                      placeholder="R12345678"
                      onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                    />
                  </Form.Item>
                </div>
                <div className="itemDS910 min-height-170px">
                  <label className="form_label_color">9. {t('common:fields.planGroupNumber')}</label>
                  <Form.Item
                    name={DENTAL_FIELDS.planGroupNumber}
                    rules={[getYupValidator(t, DENTAL_FIELDS.planGroupNumber)]}
                  >
                    <Input
                      placeholder="XYZDENTAL12345"
                      onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                    />
                  </Form.Item>
                </div>
                <div className="itemDS911 min-height-170px">
                  <label className="form_label_color">
                    10. {t('common:fields.patient.insuredRelationToSubscriber')}
                  </label>
                  <PatientsRelatopnshipToPartner patientRelationship={DENTAL_FIELDS.patientsRelationship} />
                </div>
                <div className="itemDS912 min-height-90px">
                  <label className="form_label_color">
                    11. {t('common:fields.otherInsuranceCompanyDentalBenefit')}
                  </label>
                  <FieldsetLocation
                    form="dental"
                    ids={{
                      name: DENTAL_FIELDS.dentalBenefitPlanName,
                      address: DENTAL_FIELDS.insCompanyAddress,
                      city: DENTAL_FIELDS.insCompanyCity,
                      state: DENTAL_FIELDS.insCompanyState,
                      zipcode: DENTAL_FIELDS.insCompanyZipcode
                      // zipcodeext: DENTAL_FIELDS.insCompanyZipcodeExt
                    }}
                    labels={{
                      //plan: t('common:fields.companyPlan'),
                      name: t('common:fields.dentalBenefitPlanName'),
                      address: t('common:fields.address') + ' (' + t('common:fields.streetNumber') + ')',
                      city: t('common:fields.companyCity'),
                      state: t('common:fields.companyState'),
                      zipcode: t('common:fields.companyZipcode')
                      // zipcodeext: t('common:fields.companyZipcodeExt')
                    }}
                    breakPoints={{
                      name: 4,
                      address: 4,
                      city: 4,
                      state: 4,
                      zipcode: 4
                      // zipcodeext: 4
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="itemDS10 no-padding no-border position-bottom">
              <div className="grid-container-dental-7 no-padding no-border w-100 no-bl">
                <div className="itemDS101 no-bl bt-2 br-2 padding-1-5">
                  <span className="bold mr-5">{t('common:fields.policyholderSubInfo')}</span>{' '}
                  <span className="font-size-small">{t('common:fields.forInstanceCompanyName')}</span>
                </div>
                <div className="itemDS102 no-bl br-2">
                  <label className="form_label_color">12. {t('common:fields.dentalSection12Title')}</label>
                  <FieldsetFullName
                    form="dental"
                    lastNameId={DENTAL_FIELDS.subLastName}
                    firstNameId={DENTAL_FIELDS.subFirstName}
                    middleMiddleId={DENTAL_FIELDS.subMiddle}
                    //middleInitialId={DENTAL_FIELDS.subInitial}
                    suffixId={DENTAL_FIELDS.subSuffix}
                    labels={{
                      lastName: t('common:fields.lastName'),
                      generation: t('common:fields.generationAbbr')
                    }}
                    breakPoints={{ firstName: 8, lastName: 8, middleInitial: 4, suffix: 4 }}
                  />
                  <FieldsetLocation
                    form="dental"
                    ids={{
                      address: DENTAL_FIELDS.subNameAddress,
                      city: DENTAL_FIELDS.subCity,
                      state: DENTAL_FIELDS.subState,
                      zipcode: DENTAL_FIELDS.subZip
                      // zipcodeext: DENTAL_FIELDS.subZipExt
                    }}
                    labels={{
                      address: t('common:fields.address') + ' (' + t('common:fields.streetNumber') + ')',
                      zipcode: t('common:fields.companyZipcode')
                      // zipcodeext: t('common:fields.companyZipcodeExt')
                    }}
                    breakPoints={{
                      address: 6,
                      city: 6,
                      state: 4,
                      zipcode: 4
                      // zipcodeext: 4
                    }}
                  />
                </div>
                <div className="itemDS103 no-bl">
                  <label className="form_label_color">13. {t('Date of Birth')}</label>
                  <Form.Item
                    name={DENTAL_FIELDS.subDateOfBirth}
                    rules={[
                      getYupValidator(t, DENTAL_FIELDS.subDateOfBirth),
                      getYupWarning(t, DENTAL_FIELDS.subDateOfBirth)
                    ]}
                  >
                    <DatePickerAutoPopulate
                      suffixIcon={null}
                      panelRender={() => false}
                      format={DATE_FORMAT}
                      placeholder={t('common:fields.dateFormat')}
                      style={{ width: '100%' }}
                      name={DENTAL_FIELDS.subDateOfBirth}
                      form={form!}
                    />
                  </Form.Item>
                </div>
                <div className="itemDS104">
                  <label className="form_label_color">14. {t('common:fields.gender')}</label>
                  <FormItemSex id={DENTAL_FIELDS.subGender} hideLabel={true} />
                </div>
                <div className="itemDS105 br-2">
                  <label className="form_label_color">15. {t('common:fields.policyholderSubscriberID')}</label>
                  <Form.Item name={DENTAL_FIELDS.subId} rules={[getYupValidator(t, DENTAL_FIELDS.subId)]}>
                    <Input
                      placeholder="R12345678"
                      onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                    />
                  </Form.Item>
                </div>
                <div className="itemDS106 no-bl">
                  <label className="form_label_color">16. {t('common:fields.planGroupNumber')}</label>
                  <Form.Item
                    name={DENTAL_FIELDS.subGroupNumber}
                    rules={[getYupValidator(t, DENTAL_FIELDS.subGroupNumber)]}
                  >
                    <Input
                      placeholder="XYZDENTAL12345"
                      onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                    />
                  </Form.Item>
                </div>
                <div className="itemDS107 br-2">
                  <label className="form_label_color">17. {t('common:fields.employerName')}</label>
                  <Form.Item
                    name={DENTAL_FIELDS.subEmployerName}
                    rules={[getYupValidator(t, DENTAL_FIELDS.subEmployerName)]}
                  >
                    <Input
                      placeholder="FULL NAME"
                      onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                    />
                  </Form.Item>
                </div>
                <div className="itemDS108 no-bl br-2 padding-1-5">
                  <span className="bold mr-5">{'PATIENT INFORMATION'}</span>
                </div>
                <div className="itemDS109 no-bl">
                  <label className="form_label_color">
                    18. {t('common:fields.patient.relationshipToPolicyHolder12')}
                  </label>
                  {/* <PatientsRelatopnshipToPartner
                    type="paper"
                    label={t('common:fields.patientsRelationship')}
                    patientRelationship={DENTAL_FIELDS.patientsRelationship2}
                  /> */}
                  <Form.Item
                    name={DENTAL_FIELDS.patientsRelationship2}
                    rules={[getYupValidator(t, DENTAL_FIELDS.patientsRelationship2)]}
                  >
                    <Radio.Group name={DENTAL_FIELDS.patientsRelationship2} className="flex-column">
                      <Radio onClick={nullifyRadioGroup} value="self">
                        {t('common:self')}
                      </Radio>
                      <Radio onClick={nullifyRadioGroup} value="spouse">
                        {t('common:spouse')}
                      </Radio>
                      <Radio onClick={nullifyRadioGroup} value="dependent">
                        {t('common:dependent')}
                      </Radio>
                      <Radio onClick={nullifyRadioGroup} value="other">
                        {t('common:other')}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </div>
                <div className="itemDS1010 br-2">
                  <label className="form_label_color">19. {t('common:fields.reservedForFuture')}</label>
                  <Form.Item
                    name={DENTAL_FIELDS.reservedForFuture}
                    rules={[getYupValidator(t, DENTAL_FIELDS.reservedForFuture)]}
                  >
                    <Input placeholder="Use" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                  </Form.Item>
                </div>
                <div className="itemDS1011 no-bl br-2 min-height-170px">
                  <label className="form_label_color">20. PATIENT NAME AND ADDRESS</label>
                  <FieldsetFullName
                    form="dental"
                    lastNameId={DENTAL_FIELDS.patLastName}
                    firstNameId={DENTAL_FIELDS.patFirstName}
                    middleMiddleId={DENTAL_FIELDS.patMiddle}
                    suffixId={DENTAL_FIELDS.patSuffix}
                    labels={{
                      lastName: t('common:fields.lastName'),
                      generation: t('common:fields.generationAbbr')
                    }}
                    breakPoints={{ firstName: 8, lastName: 8, middleInitial: 4, suffix: 4 }}
                  />

                  <FieldsetLocation
                    form="dental"
                    ids={{
                      address: DENTAL_FIELDS.patAddress,
                      city: DENTAL_FIELDS.patCity,
                      state: DENTAL_FIELDS.patState,
                      zipcode: DENTAL_FIELDS.patZip
                      // zipcodeext: DENTAL_FIELDS.patZipExt
                    }}
                    labels={{
                      address: t('common:fields.address') + ' (' + t('common:fields.streetNumber') + ')',
                      zipcode: t('common:fields.companyZipcode')
                      // zipcodeext: t('common:fields.companyZipcodeExt')
                    }}
                    breakPoints={{
                      address: 4,
                      city: 4,
                      state: 4,
                      zipcode: 4
                      // zipcodeext: 4
                    }}
                  />
                </div>
                <div className="itemDS1012 min-height-90px no-bl">
                  <label className="form_label_color">21. {t('Date of Birth')}</label>
                  <Form.Item
                    name={DENTAL_FIELDS.patDateOfBirth}
                    rules={[
                      getYupValidator(t, DENTAL_FIELDS.patDateOfBirth),
                      getYupWarning(t, DENTAL_FIELDS.patDateOfBirth)
                    ]}
                  >
                    <DatePickerAutoPopulate
                      suffixIcon={null}
                      panelRender={() => false}
                      format={DATE_FORMAT}
                      placeholder={t('common:fields.dateFormat')}
                      style={{ width: '100%' }}
                      name={DENTAL_FIELDS.patDateOfBirth}
                      form={form!}
                    />
                  </Form.Item>
                </div>
                <div className="itemDS1013 min-height-90px">
                  <label className="form_label_color">22. {t('common:fields.gender')}</label>
                  <FormItemSex id={DENTAL_FIELDS.patGender} hideLabel={true} />
                </div>
                <div className="itemDS1014 min-height-90px br-2">
                  <label className="form_label_color">23. {t('common:fields.patientId')}</label>
                  <Form.Item name={DENTAL_FIELDS.patIdAccNo} rules={[getYupValidator(t, DENTAL_FIELDS.patIdAccNo)]}>
                    <Input placeholder="KO0054" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="section-wrapper w-100_05 ">
            <section className="bl-2 br-2 padding-1-5">
              <label>
                <span className="bold">{t('common:fields.recordOfServicesProvided')}</span>{' '}
              </label>
            </section>
          </div>
          <div className="section-wrapper w-100_05 ">
            <section className="bl-2 br-2">
              <ServicesFieldsList readOnly={disabled} />
            </section>
            <div className="flex inputs-wrapper">
              <section className="missing-tooth-info bl-2">
                <label style={{ paddingBottom: '5px' }}>
                  <span className="form_label_color">33. {t('common:fields.missingTeethInfo')}</span>{' '}
                  <span className="font-size-small"> {t('common:fields.placeXOnMissingTooth')}</span>
                </label>
                <div>
                  <MissingTeethInformation />
                </div>
              </section>
              <section className="missing-tooth-info br-2 bl-2">
                <div>
                  <span className="form_label_color missing-tooth-info">
                    34. {t('common:fields.diagnosisCodeListQualifier')}
                  </span>
                  <Form.Item name={DENTAL_FIELDS.diagCodeList} rules={[getYupValidator(t, DENTAL_FIELDS.diagCodeList)]}>
                    <Input placeholder="0" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                  </Form.Item>
                </div>
                <div className="margin-top-15">
                  <span className="form_label_color missing-tooth-info">
                    34a. {t('common:fields.diagnosisCodesprimaryA')}
                  </span>
                  <div>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={12}>
                        <Form.Item name={DENTAL_FIELDS.dxCodeA} rules={[getYupValidator(t, DENTAL_FIELDS.dxCodeA)]}>
                          <Input
                            placeholder="A01.20"
                            onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item name={DENTAL_FIELDS.dxCodeB} rules={[getYupValidator(t, DENTAL_FIELDS.dxCodeC)]}>
                          <Input
                            placeholder="B01.23"
                            onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} sm={12}>
                        <Form.Item name={DENTAL_FIELDS.dxCodeC} rules={[getYupValidator(t, DENTAL_FIELDS.dxCodeB)]}>
                          <Input
                            placeholder="C01.21"
                            onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={24} sm={12}>
                        <Form.Item name={DENTAL_FIELDS.dxCodeD} rules={[getYupValidator(t, DENTAL_FIELDS.dxCodeD)]}>
                          <Input
                            placeholder="D01.24"
                            onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                </div>
              </section>

              <section className="missing-tooth-info w-100 br-2">
                <div className="form_label_color">34b.</div>
                <div>
                  <Form.Item
                    label={<span className="">{t('common:fields.resubmissionCode')}</span>}
                    name={DENTAL_FIELDS.resubmissionCode}
                  >
                    <Select
                      allowClear
                      options={RESUBMISSION_CODES_HCFA.map((el) => ({ label: el, value: el }))}
                      showSearch
                      onSearch={(input) => {
                        onSearchSelect(form, DENTAL_FIELDS.resubmissionCode, input, RESUBMISSION_CODES_HCFA);
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={t('common:fields.originalRefNumber')}
                    name={DENTAL_FIELDS.originalRefNumber}
                    rules={[getYupValidator(t, DENTAL_FIELDS.originalRefNumber)]}
                  >
                    <Input
                      placeholder="AAAAA123456789"
                      onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                      onChange={(e) => {
                        form?.setFieldValue(DENTAL_FIELDS.originalRefNumber, e.target.value);
                      }}
                      suffix={
                        <Tooltip title={t('dental:originalRefInfo')}>
                          {showOriginalRefInfo && <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />}
                        </Tooltip>
                      }
                    />
                  </Form.Item>
                </div>
                {/* <Form.Item
                  label={<span className="form_label_color">{t('common:fields.totalCharge')}</span>}
                  name={DENTAL_FIELDS.totalFee}
                  rules={[getYupValidator(t, DENTAL_FIELDS.totalFee)]}
                >
                  <Input
                    onPaste={toCurrencyPaste}
                    onInput={toCurrency}
                    prefix="$"
                    placeholder="50.00"
                    minLength={2}
                  />
                </Form.Item> */}
              </section>
            </div>
            <section className="bl-2 br-2">
              <label className="form_label_color">35. {t('common:fields.remarks') + ' and First visit date'}</label>
              <div className="flex" style={{ alignItems: 'flex-end' }}>
                <Form.Item name={DENTAL_FIELDS.remarks} rules={[getYupValidator(t, DENTAL_FIELDS.remarks)]}>
                  <Input placeholder="REMARKS" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                </Form.Item>
                <Form.Item
                  name={DENTAL_FIELDS.firstVisitDate}
                  rules={[
                    getYupValidator(t, DENTAL_FIELDS.firstVisitDate),
                    getYupWarning(t, DENTAL_FIELDS.firstVisitDate)
                  ]}
                  style={{ maxWidth: '250px' }}
                >
                  <DatePickerAutoPopulate
                    suffixIcon={null}
                    placeholder={t('FIRST VISIT DATE')}
                    panelRender={() => false}
                    format={DATE_FORMAT}
                    name={DENTAL_FIELDS.firstVisitDate}
                    form={form!}
                  />
                </Form.Item>
              </div>
            </section>
          </div>
          <div className="grid-container-dental-1">
            <div className="itemD1 padding-1-5 bl-2">
              <span className="bold">{t('common:fields.authorizations')}</span>
            </div>
            <div className="itemD2 padding-1-5 br-2">
              <span className="bold">{t('common:fields.ancillaryClaimInfo')}</span>
            </div>
            <div className="itemD3 flex-d-column no-padding bl-2">
              <div className="w-100 section-padding bb">
                <div className="flex-align-start">
                  <div className="form_label_color">36. </div>
                  <div>{t('common:iHaveBeenInformedText')}</div>
                </div>
                <div className="flex">
                  <Form.Item label={t('common:fields.signed')} name={DENTAL_FIELDS.patGuardSignature}>
                    <Select
                      showSearch
                      onSearch={(input) => {
                        onSearchSelect(form, DENTAL_FIELDS.patGuardSignature, input, ['Signed', '']);
                      }}
                      optionFilterProp="key"
                    >
                      {SIGNATURE_OPTIONS.map((code) => (
                        <Select.Option key={code.label} value={code.value}>
                          {code.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={t('common:fields.date.date')}
                    name={DENTAL_FIELDS.patGuardSignatureDate}
                    rules={[
                      getYupValidator(t, DENTAL_FIELDS.patGuardSignatureDate)
                      //getYupWarning(t, DENTAL_FIELDS.patGuardSignatureDate)
                    ]}
                  >
                    <DatePickerAutoPopulate
                      suffixIcon={null}
                      placeholder={t('common:fields.dateFormat')}
                      panelRender={() => false}
                      format={DATE_FORMAT}
                      name={DENTAL_FIELDS.patGuardSignatureDate}
                      form={form!}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="w-100 section-padding bt">
                <div className="flex-align-start">
                  <div className="form_label_color">37. </div>
                  <div>{t('common:iHerebyAuthorizeAndDirect')}</div>
                </div>
                <div className="flex">
                  <Form.Item label={t('common:fields.signed')} name={DENTAL_FIELDS.subSignatureInd}>
                    <Select
                      showSearch
                      onSearch={(input) => {
                        onSearchSelect(form, DENTAL_FIELDS.subSignatureInd, input, ['Signed', '']);
                      }}
                      optionFilterProp="key"
                    >
                      {SIGNATURE_OPTIONS.map((code) => (
                        <Select.Option key={code.label} value={code.value}>
                          {code.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={t('common:fields.date.date')}
                    name={DENTAL_FIELDS.subSignatureDate}
                    rules={[
                      getYupValidator(t, DENTAL_FIELDS.subSignatureDate)
                      //getYupWarning(t, DENTAL_FIELDS.subSignatureDate)
                    ]}
                  >
                    <DatePickerAutoPopulate
                      suffixIcon={null}
                      placeholder={t('common:fields.dateFormat')}
                      panelRender={() => false}
                      format={DATE_FORMAT}
                      name={DENTAL_FIELDS.subSignatureDate}
                      form={form!}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="itemD4 grid-container-dental-2 no-padding no-border bl">
              <div className="itemD41">
                <label className="form_label_color">38. {'Place of Treatment'}</label>
                <Form.Item name={DENTAL_FIELDS.placeOfTreat} rules={[getYupValidator(t, DENTAL_FIELDS.placeOfTreat)]}>
                  <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                </Form.Item>
              </div>
              <div className="itemD42 br-2">
                <label className="form_label_color">39. {'Enclosures'}</label>
                <Form.Item name={DENTAL_FIELDS.enclosures} rules={[getYupValidator(t, DENTAL_FIELDS.enclosures)]}>
                  <Select
                    placeholder="Y or N"
                    options={[
                      { value: 'Y', label: 'Y' },
                      { value: 'N', label: 'N' }
                    ]}
                    showSearch
                    onSearch={(input) => {
                      onSearchSelect(form, DENTAL_FIELDS.enclosures, input, ['Y', 'N']);
                    }}
                  />
                </Form.Item>
              </div>
              <div className="itemD43">
                <label className="form_label_color">40. {'Is Treatment for Orthodontics?'}</label>
                <Form.Item
                  name={DENTAL_FIELDS.orthoTreatment}
                  rules={[getYupValidator(t, DENTAL_FIELDS.orthoTreatment)]}
                >
                  <Select
                    placeholder="Y or N"
                    options={[
                      { value: 'Y', label: 'Y' },
                      { value: 'N', label: 'N' }
                    ]}
                    showSearch
                    onSearch={(input) => {
                      onSearchSelect(form, DENTAL_FIELDS.orthoTreatment, input, ['Y', 'N']);
                    }}
                  />
                </Form.Item>
              </div>
              <div className="itemD44 br-2">
                <label className="form_label_color">41. {'Date Appliance Placed'}</label>
                <Form.Item
                  name={DENTAL_FIELDS.appPlaceDate}
                  rules={[getYupValidator(t, DENTAL_FIELDS.appPlaceDate), getYupWarning(t, DENTAL_FIELDS.appPlaceDate)]}
                >
                  <DatePickerAutoPopulate
                    suffixIcon={null}
                    placeholder={t('common:fields.dateFormat')}
                    panelRender={() => false}
                    format={DATE_FORMAT}
                    name={DENTAL_FIELDS.appPlaceDate}
                    form={form!}
                  />
                </Form.Item>
              </div>
              <div className="itemD45">
                <label className="form_label_color">42. {'Months of Treatment Remaining'}</label>
                <Form.Item name={DENTAL_FIELDS.monthOfTreat} rules={[getYupValidator(t, DENTAL_FIELDS.monthOfTreat)]}>
                  <Input />
                </Form.Item>
              </div>
              <div className="itemD46">
                <label className="form_label_color">43. {'Replacement of Prosthesis?'}</label>
                <Form.Item name={DENTAL_FIELDS.replacePros}>
                  <Select
                    placeholder="Y or N"
                    options={[
                      { value: 'Y', label: 'Y' },
                      { value: 'N', label: 'N' }
                    ]}
                    showSearch
                    onSearch={(input) => {
                      onSearchSelect(form, DENTAL_FIELDS.replacePros, input, ['Y', 'N']);
                    }}
                  />
                </Form.Item>
              </div>
              <div className="itemD47 br-2">
                <label className="form_label_color">44. {'Date Prior Placement '}</label>
                <Form.Item
                  name={DENTAL_FIELDS.priorPlacement}
                  rules={[
                    getYupValidator(t, DENTAL_FIELDS.priorPlacement),
                    getYupWarning(t, DENTAL_FIELDS.priorPlacement)
                  ]}
                >
                  <DatePickerAutoPopulate
                    suffixIcon={null}
                    placeholder={t('common:fields.dateFormat')}
                    panelRender={() => false}
                    format={DATE_FORMAT}
                    name={DENTAL_FIELDS.priorPlacement}
                    form={form!}
                  />
                </Form.Item>
              </div>
              <div className="itemD48 br-2">
                <label className="form_label_color">45. {'Treatment Resulting from (Check applicable box)'}</label>
                <div className="flex">
                  <Form.Item
                    name={DENTAL_FIELDS.tretResultingForm}
                    normalize={(value, prev) =>
                      value.filter((_v: string) => !prev?.some((_val: string) => _val === _v))
                    }
                  >
                    <Checkbox.Group>
                      <Checkbox value="1">{t('dental:form.tretResulting.ocucupational')}</Checkbox>
                      <Checkbox style={{ marginLeft: 0 }} value="2">
                        {t('dental:form.tretResulting.auto')}
                      </Checkbox>
                      <Checkbox style={{ marginLeft: 0 }} value="3">
                        {t('dental:form.tretResulting.other')}
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                </div>
              </div>
              <div className="itemD49">
                <label className="form_label_color">46. {'Date of Accident'}</label>
                <Form.Item
                  name={DENTAL_FIELDS.accDate}
                  rules={[getYupValidator(t, DENTAL_FIELDS.accDate), getYupWarning(t, DENTAL_FIELDS.accDate)]}
                >
                  <DatePickerAutoPopulate
                    suffixIcon={null}
                    placeholder={t('common:fields.dateFormat')}
                    panelRender={() => false}
                    format={DATE_FORMAT}
                    name={DENTAL_FIELDS.accDate}
                    form={form!}
                  />
                </Form.Item>
              </div>
              <div className="itemD410 br-2">
                <label className="form_label_color">47. {'Auto accident state'}</label>
                <Form.Item name={DENTAL_FIELDS.accState} rules={[getYupValidator(t, DENTAL_FIELDS.accState)]}>
                  <SelectState placeholder="STATE" inputKey={DENTAL_FIELDS.accState} />
                </Form.Item>
              </div>
            </div>
            <div className="itemD5 grid-container-dental-3 no-padding no-border">
              <div className="itemD51 br padding-5 bl-2">
                <label>
                  <span className="bold">{t('common:billingDentistOrDentalEntity')}</span>
                  <span className="font-size-small">{t('common:leaveBlankIfDentistNoSubmit')}</span>
                </label>
              </div>
              <div className="itemD52 br bl-2">
                <label className="form_label_color">48. {t('common:fields:48Label')}</label>
                <Form.Item
                  label={'ORGANIZATION NAME'}
                  name={DENTAL_FIELDS.organizationName}
                  rules={[getYupValidator(t, DENTAL_FIELDS.organizationName)]}
                >
                  <Input
                    placeholder="ORGANIZATION NAME"
                    onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                  />
                </Form.Item>
                <FieldsetFullName
                  form="dental"
                  lastNameId={DENTAL_FIELDS.billingLastName}
                  firstNameId={DENTAL_FIELDS.billingFirstName}
                  middleMiddleId={DENTAL_FIELDS.billingMiddleName}
                  suffixId={DENTAL_FIELDS.billingSuffix}
                  // labels={{
                  //   lastName: t('common:fields.lastName'),
                  //   firstName: t('common:fields.lastName')
                  // }}
                  breakPoints={{ firstName: 8, lastName: 8, middleInitial: 4, suffix: 4 }}
                />
                <Form.Item label={'Title'} name={DENTAL_FIELDS.billingTitle}>
                  <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                </Form.Item>
                <div className="flex" style={{ gap: '16px' }}>
                  <Form.Item
                    label={'Address 1'}
                    name={DENTAL_FIELDS.billingNameAddress}
                    rules={[getYupValidator(t, DENTAL_FIELDS.billingNameAddress)]}
                  >
                    <Input
                      placeholder="ADDRESS 1"
                      onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                    />
                  </Form.Item>
                  <Form.Item
                    label={'Address 2'}
                    name={DENTAL_FIELDS.billingAddress2}
                    rules={[getYupValidator(t, DENTAL_FIELDS.billingAddress2)]}
                  >
                    <Input
                      placeholder="ADDRESS 2"
                      onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                    />
                  </Form.Item>
                </div>
                <FieldsetLocation
                  form="dental"
                  ids={{
                    //address: DENTAL_FIELDS.billingNameAddress,
                    city: DENTAL_FIELDS.billingCity,
                    state: DENTAL_FIELDS.billingState,
                    zipcode: DENTAL_FIELDS.billingZip
                    // zipcodeext: DENTAL_FIELDS.billingZipExt
                  }}
                  labels={{
                    name: 'Name',
                    //address: 'Address 1',
                    zipcode: t('common:fields.zipCode')
                    // zipcodeext: t('common:fields.zipCodeExt')
                  }}
                  breakPoints={{
                    //address: 6,
                    city: 6,
                    state: 6,
                    zipcode: 6
                    // zipcodeext: 6
                  }}
                />
              </div>
              <div className="itemD53 bl-2">
                <label className="form_label_color">49. {t('common:fields.npi')}</label>
                <Form.Item name={DENTAL_FIELDS.billingNpi} rules={[getYupValidator(t, DENTAL_FIELDS.billingNpi)]}>
                  <Input placeholder="0123456789" />
                </Form.Item>
              </div>
              <div className="itemD54">
                <label className="form_label_color">50. {t('common:fields.licenseNumber')}</label>
                <Form.Item name={DENTAL_FIELDS.billingLic} rules={[getYupValidator(t, DENTAL_FIELDS.billingLic)]}>
                  <Input placeholder="123456" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                </Form.Item>
              </div>
              <div className="itemD55 br">
                <label className="form_label_color">51. {t('common:fields.billingSSNorTIN')}</label>
                <Form.Item
                  name={DENTAL_FIELDS.billingSSNorTIN}
                  rules={[getYupValidator(t, DENTAL_FIELDS.billingSSNorTIN)]}
                >
                  <Input
                    placeholder="123456789"
                    onInput={(e: any) => {
                      if (e.target.value.length > 9 && e.target.value[0] === '0') {
                        e.target.value = e.target.value.slice(1);
                      }
                      toPositiveNumber(e);
                    }}
                    onKeyDown={(e) => handle52DentalKeyDown(e)}
                  />
                </Form.Item>
                <br />
                {/* <label className="form_label_color">51. {t('common:fields.billingAddId')}</label>
                <Form.Item name={DENTAL_FIELDS.billingAddId} rules={[getYupValidator(t, DENTAL_FIELDS.billingAddId)]}>
                  <Input placeholder="" />
                </Form.Item> */}
              </div>
            </div>
            <div className="itemD6 grid-container-dental-4 no-padding">
              <div className="itemD61 br-2 padding-1-5">
                <label className="bold">{t('common:fields.treatingDentistAndTreatment')}</label>
              </div>
              <div className="itemD62 br-2">
                <div className="flex-align-start">
                  <div className="form_label_color">53. </div>
                  <div>{t('common:iHerebyCertifyThat')}</div>
                </div>
                <div className="flex">
                  <Form.Item label={t('common:fields.signed')} name={DENTAL_FIELDS.treatSign}>
                    <Select
                      showSearch
                      onSearch={(input) => {
                        onSearchSelect(form, DENTAL_FIELDS.treatSign, input, ['Signed', '']);
                      }}
                      optionFilterProp="key"
                    >
                      {SIGNATURE_OPTIONS.map((code) => (
                        <Select.Option key={code.label} value={code.value}>
                          {code.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label={t('common:fields.date.date')}
                    name={DENTAL_FIELDS.treatSignDate}
                    rules={[
                      getYupValidator(t, DENTAL_FIELDS.treatSignDate)
                      //getYupWarning(t, DENTAL_FIELDS.treatSignDate)
                    ]}
                  >
                    <DatePickerAutoPopulate
                      suffixIcon={null}
                      placeholder={t('common:fields.dateFormat')}
                      panelRender={() => false}
                      format={DATE_FORMAT}
                      name={DENTAL_FIELDS.treatSignDate}
                      form={form!}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="itemD63">
                <label className="form_label_color"> 54. {'Treating ' + t('common:fields.npi')}</label>
                <Form.Item name={DENTAL_FIELDS.treatNpi} rules={[getYupValidator(t, DENTAL_FIELDS.treatNpi)]}>
                  <Input placeholder="123456789" />
                </Form.Item>
              </div>
              <div className="itemD64 br-2">
                <label className="form_label_color"> 55. {'Treating ' + t('common:fields.licenseNumber')}</label>
                <Form.Item name={DENTAL_FIELDS.treatLic} rules={[getYupValidator(t, DENTAL_FIELDS.treatLic)]}>
                  <Input placeholder="123456" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                </Form.Item>
              </div>
              <div className="itemD65 no-bb">
                <div className="w-50" style={{ padding: '5px' }}>
                  <label className="form_label_color"> 56. {t('common:fields.56Label')}</label>
                </div>
              </div>
              <div className="itemD66 br-2 bb-2">
                <label className="form_label_color">56a. {'Provider Speciality Code'} </label>
                <Form.Item name={DENTAL_FIELDS.provSpecCode} rules={[getYupValidator(t, DENTAL_FIELDS.provSpecCode)]}>
                  <Input
                    placeholder="123P0221X"
                    onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                  />
                </Form.Item>
              </div>
              <div className="itemD67 br-2 no-bt">
                <FieldsetFullName
                  form="dental"
                  lastNameId={DENTAL_FIELDS.treatLastName}
                  firstNameId={DENTAL_FIELDS.treatFirstName}
                  middleMiddleId={DENTAL_FIELDS.treatMiddleName}
                  suffixId={DENTAL_FIELDS.treatSuffix}
                  breakPoints={{ firstName: 8, lastName: 8, middleInitial: 4, suffix: 4 }}
                  labels={{
                    lastName: t('common:fields.lastName'),
                    firstName: t('common:fields.firstName')
                  }}
                />
                <Form.Item label={'Title'} name={DENTAL_FIELDS.treatTitle}>
                  <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                </Form.Item>
                <div className="flex" style={{ gap: '16px' }}>
                  <Form.Item
                    label={'Address 1'}
                    name={DENTAL_FIELDS.treatAddress}
                    rules={[getYupValidator(t, DENTAL_FIELDS.treatAddress)]}
                  >
                    <Input
                      placeholder="ADDRESS 1"
                      onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                    />
                  </Form.Item>
                  <Form.Item
                    label={'Address 2'}
                    name={DENTAL_FIELDS.treatAddress2}
                    rules={[getYupValidator(t, DENTAL_FIELDS.treatAddress2)]}
                  >
                    <Input
                      placeholder="ADDRESS 2"
                      onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                    />
                  </Form.Item>
                </div>
                <FieldsetLocation
                  form="dental"
                  ids={{
                    //address: DENTAL_FIELDS.treatAddress,
                    city: DENTAL_FIELDS.treatCity,
                    state: DENTAL_FIELDS.treatState,
                    zipcode: DENTAL_FIELDS.treatZip
                    // zipcodeext: DENTAL_FIELDS.treatZipExt
                  }}
                  labels={{
                    //address: 'Address 1',
                    zipcode: t('common:fields.zipCode')
                    // zipcodeext: t('common:fields.zipCodeExt')
                  }}
                  breakPoints={{
                    //address: 4,
                    city: 6,
                    state: 6,
                    zipcode: 6
                    // zipcodeext: 6
                  }}
                />
              </div>
            </div>
            <div className="itemD7 flex no-padding">
              <div className="w-50 h-100 padding-5 br bb bl">
                <label className="form_label_color">52. {t('common:fields.billingPhoneNumber')}</label>
                <Form.Item className="mb-0" rules={[getYupValidator(t, DENTAL_FIELDS.billingPhone)]}>
                  <Row gutter={8} wrap={false}>
                    {/* <Col>
                      <Form.Item
                        name={DENTAL_FIELDS.billingPhoneCode}
                        rules={[getYupValidator(t, DENTAL_FIELDS.billingPhoneCode)]}
                      >
                        <SelectPhoneCode />
                      </Form.Item>
                    </Col> */}
                    <Col flex="auto">
                      <Form.Item
                        name={DENTAL_FIELDS.billingPhone}
                        rules={[getYupValidator(t, DENTAL_FIELDS.billingPhone)]}
                      >
                        <Input placeholder="PHONE NUMBER" onInput={toNum} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </div>
              <div className="w-50 h-100 section-padding bl bb">
                <label className="form_label_color">52a. {t('common:fields.additionalProviderIdDental')}</label>
                <Form.Item
                  name={DENTAL_FIELDS.additionalProviderIdDental}
                  rules={[getYupValidator(t, DENTAL_FIELDS.additionalProviderIdDental)]}
                >
                  <Input
                    onKeyDown={(e) => handle52ADentalKeyDown(e)}
                    onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="itemD8 flex no-padding">
              <div className="w-50 h-100 section-padding br-2 bb">
                <label className="form_label_color">57. {t('common:fields.treatingPhoneNumber')}</label>
                <Form.Item className="mb-0" rules={[getYupValidator(t, DENTAL_FIELDS.treatingPhone)]}>
                  <Row gutter={8} wrap={false}>
                    {/* <Col>
                      <Form.Item
                        name={DENTAL_FIELDS.treatingPhoneCode}
                        rules={[getYupValidator(t, DENTAL_FIELDS.treatingPhoneCode)]}
                      >
                        <SelectPhoneCode />
                      </Form.Item>
                    </Col> */}
                    <Col flex="auto">
                      <Form.Item
                        name={DENTAL_FIELDS.treatingPhone}
                        rules={[getYupValidator(t, DENTAL_FIELDS.treatingPhone)]}
                      >
                        <Input placeholder="PHONE NUMBER" onInput={toNum} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
              </div>
              <div className="w-50 h-100 section-padding br bb">
                <label className="form_label_color">58. {'Provider ID'}</label>
                <Form.Item name={DENTAL_FIELDS.providerId} rules={[getYupValidator(t, DENTAL_FIELDS.providerId)]}>
                  <Input placeholder="" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                </Form.Item>
              </div>
            </div>
          </div>
          {actions}
        </Form>
      </div>
    </>
  );
}

export { PaperForm };
