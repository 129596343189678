import { useOidc } from '@axa-fr/react-oidc';
import { Button } from '@datum-components/react-components';
import React from 'react';

const POST_LOGIN_REDIRECT = window.location.origin + '/landing';

const LogoutPage = () => {
  const { login } = useOidc();

  return (
    <div style={{ maxWidth: '600px', margin: '10% auto' }}>
      <h1 style={{ fontSize: '30px', textTransform: 'uppercase' }}>Thank you!</h1>
      <p>
        Thank you for being a part of the <b>RapidPortal</b> community. We look forward to serving you and meeting your
        needs in the future.
      </p>
      <br />
      <Button variant="primary" onClick={() => login(POST_LOGIN_REDIRECT)}>
        Go to Login page
      </Button>
      <Button
        style={{ marginLeft: '1rem' }}
        variant="outline"
        onClick={() => (window.location.href = 'https://www.caresource.com/providers/provider-portal/')}
      >
        Go to Caresource Provider Portal
      </Button>
    </div>
  );
};

export default LogoutPage;
