import * as React from 'react';
import { Breadcrumb } from '@datum-components/react-components';
import { useBreadcrumb } from 'context/breadcrumb.context';
import { Link } from 'react-router-dom';

function AppBreadcrumb(): JSX.Element {
  const { breadcrumbItems } = useBreadcrumb();

  if (breadcrumbItems.length > 1) {
    return (
      <div className="app-breadcrumb-wrapper">
        <Breadcrumb>
          {breadcrumbItems.map((item, i) => (
            <Breadcrumb.Item key={item.key} isCurrentPage={i === breadcrumbItems.length - 1}>
              <Breadcrumb.Link as={Link} to={item.to}>
                {item.name}
              </Breadcrumb.Link>
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>
    );
  } else {
    return <></>;
  }
}

export { AppBreadcrumb };
