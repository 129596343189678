import React, { useState, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import { DatePicker, Form, Input, Table, Tooltip, Select, Modal } from 'antd';
import { UB04_FIELDS } from 'utils/ub04';
import { DeleteOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, IconButton } from '@datum-components/react-components';
import { getYupWarningServiceTable } from 'utils/ub04/validataions';
import { getYupValidatorServiceTable } from '../../../utils/ub04/validataions';

import { DATE_FORMAT } from '../../../utils/hcfaConstants';
import { FormContext } from 'components/common/FormProvider';
import { toCurrency, toCurrencyPaste } from 'helpers/toCurrency';
import { toPositiveNumber } from 'helpers/toPositiveNumber';
import { toPositiveFloat } from 'helpers/toPositiveFloat';
import { toAlphaNumUppercase } from 'helpers/toAlphaNumUppercase';
import { onSearchSelect } from 'utils/search/search';
import DatePickerAutoPopulate from 'components/common/DatePickerAutoPopulate';
import TableTotals from './TableTotals';
import { handleRevenueCode } from 'helpers/handleRevenueCode';

interface Props {
  readOnly: boolean;
}

const PER_PAGE = 22;

const NDC_QUALIFIER = ['F2', 'GR', 'ME', 'ML', 'UN'];

function PaperServicesList({ readOnly }: Props): JSX.Element {
  const { t } = useTranslation();
  const { form } = useContext(FormContext);

  const [currentPage, setCurrentPage] = useState(1);
  const total = useRef(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalRow, setModalRow] = useState<number>();

  const onAdd = (add: (defaultValue?: any, insertIndex?: number | undefined) => void) => {
    const totalCount = total.current;
    if (totalCount % PER_PAGE === 0) {
      setCurrentPage(totalCount / PER_PAGE + 1);
    }
    add();

    const tableValues = form?.getFieldValue(['42Services']);

    if (tableValues && tableValues.length) {
      const idx = tableValues.length - 1;
      setTimeout(() => {
        const inputEl = document.getElementById(`${UB04_FIELDS.rev_cd_42}__${idx}`);
        if (inputEl) inputEl.focus();
      }, 10);
    }
  };

  const showModal = (open: boolean) => {
    setIsModalOpen(open);
  };

  const columns: ColumnsType<any> = [
    {
      key: UB04_FIELDS.field_43_description,
      render: (field) => (
        <Form.Item hidden className="hidden" name={[field.name, UB04_FIELDS.field_43_description]}>
          <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
        </Form.Item>
      )
    },
    {
      title: `S.No`,
      key: UB04_FIELDS.serialNumber,
      fixed: 'left',
      render(_v, _r, _i) {
        return _v.name + 1;
      }
    },
    {
      title: `42. REV CODE`,
      key: '42_rev_cd',
      fixed: 'left',
      width: '5%',
      render: (field) => (
        <Form.Item
          label="Revenue Code"
          className="CODE"
          name={[field.name, UB04_FIELDS.rev_cd_42]}
          rules={[getYupValidatorServiceTable(t, UB04_FIELDS.rev_cd_42)]}
        >
          <Input
            onInput={(e) => {
              toPositiveNumber(e);
              handleRevenueCode(e);
            }}
            id={`${UB04_FIELDS.rev_cd_42}__${field.name}`}
            style={{ width: '70px' }}
          />
        </Form.Item>
      )
    },
    {
      title: `43. NDC Code`,
      key: 'NDC_code',
      fixed: 'left',
      render: (field) => (
        <div className="vertical_align_with_space">
          <Tooltip title="Code">
            <Form.Item
              name={[field.name, UB04_FIELDS.desc_ndc_code]}
              rules={[getYupValidatorServiceTable(t, UB04_FIELDS.desc_ndc_code)]}
            >
              <Input
                placeholder="12345678901"
                style={{ width: '70px' }}
                onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
              />
            </Form.Item>
          </Tooltip>
        </div>
      )
    },
    {
      title: `43. NDC Qual`,
      key: 'NDC_cty',
      //fixed: 'left',
      //width: '5%',
      render: (field) => (
        <div className="vertical_align_with_space">
          <Tooltip title="Quantity">
            <Form.Item
              name={[field.name, UB04_FIELDS.desc_ndc_qty]}
              rules={[getYupValidatorServiceTable(t, UB04_FIELDS.desc_ndc_qty)]}
            >
              <Select
                maxLength={2}
                placeholder="F2"
                allowClear
                showSearch
                options={NDC_QUALIFIER.map((el: any) => ({ label: el, value: el }))}
                style={{ width: '65px' }}
                onSearch={(input) => {
                  onSearchSelect(
                    form,
                    [UB04_FIELDS.servicesList, field.name, UB04_FIELDS.desc_ndc_qty],
                    input,
                    NDC_QUALIFIER
                  );
                }}
              />
            </Form.Item>
          </Tooltip>
        </div>
      )
    },
    {
      title: `43. NDC Units`,
      key: 'NDC_units',
      //fixed: 'left',
      width: '5%',
      render: (field) => (
        <div className="vertical_align_with_space">
          <Tooltip title="Units">
            <Form.Item
              name={[field.name, UB04_FIELDS.desc_ndc_unit]}
              rules={[getYupValidatorServiceTable(t, UB04_FIELDS.desc_ndc_unit)]}
            >
              <Input
                placeholder="123"
                style={{ width: '70px' }}
                onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
              />
            </Form.Item>
          </Tooltip>
        </div>
      )
    },
    {
      title: `44. HCPCS Description`,
      key: '44_hcpcs_desc',
      //fixed: 'left',
      width: '5%',
      render: (field) => (
        <div className="vertical_align_with_space">
          <Tooltip title="HCPCS Description">
            <Form.Item
              className="mb-0"
              name={[field.name, UB04_FIELDS.hcpcs_description_44]}
              label="HCPCS Description"
              rules={[getYupValidatorServiceTable(t, UB04_FIELDS.hcpcs_description_44)]}
            >
              <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
            </Form.Item>
          </Tooltip>
        </div>
      )
    },
    {
      title: `44. HCPCS`,
      key: '44_hcpcs',
      //fixed: 'left',
      width: '5%',
      render: (field) => (
        <div className="vertical_align_with_space">
          <Tooltip title="HCPCS">
            <Form.Item
              className="mb-0"
              name={[field.name, UB04_FIELDS.hcpcs_rates_44]}
              label="HCPCS"
              rules={[getYupValidatorServiceTable(t, UB04_FIELDS.hcpcs_rates_44)]}
            >
              <Input style={{ width: '70px' }} onInput={toAlphaNumUppercase} />
            </Form.Item>
          </Tooltip>
        </div>
      )
    },
    {
      title: `Modifiers`,
      key: 'modifier_4',
      //fixed: 'left',
      width: '15%',
      render: (field) => (
        <div className="flex">
          <div className="flex">
            <div>
              <Form.Item
                name={[field.name, UB04_FIELDS.modifier_1]}
                rules={[getYupValidatorServiceTable(t, UB04_FIELDS.modifier_1)]}
              >
                <Input
                  style={{ width: '45px' }}
                  placeholder="M1"
                  onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                name={[field.name, UB04_FIELDS.modifier_2]}
                rules={[getYupValidatorServiceTable(t, UB04_FIELDS.modifier_2)]}
              >
                <Input
                  style={{ width: '45px' }}
                  placeholder="M2"
                  onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                name={[field.name, UB04_FIELDS.modifier_3]}
                rules={[getYupValidatorServiceTable(t, UB04_FIELDS.modifier_3)]}
              >
                <Input
                  style={{ width: '45px' }}
                  placeholder="M3"
                  onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                name={[field.name, UB04_FIELDS.modifier_4]}
                rules={[getYupValidatorServiceTable(t, UB04_FIELDS.modifier_4)]}
              >
                <Input
                  style={{ width: '45px' }}
                  placeholder="M4"
                  onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                />
              </Form.Item>
            </div>
          </div>
        </div>
      )
    },
    {
      title: `45. SERVICE DATE`,
      key: '45_dos',
      //fixed: 'left',
      width: '10%',
      render: (field) => (
        <Form.Item
          label="SERVICE DATE"
          className="mb-0"
          name={[field.name, UB04_FIELDS.dos_45]}
          rules={[getYupValidatorServiceTable(t, UB04_FIELDS.dos_45), getYupWarningServiceTable(t, UB04_FIELDS.dos_45)]}
        >
          <DatePickerAutoPopulate
            suffixIcon={null}
            panelRender={() => false}
            format={DATE_FORMAT}
            placeholder={t('common:fields.dateFormat')}
            className="w-full"
            // @ts-ignore
            name={[field.name, UB04_FIELDS.dos_45]}
            form={form!}
            tableName={'42Services'}
          />
        </Form.Item>
      )
    },
    {
      title: `46. SERVICE UNITS`,
      key: '46_serv_units',
      //fixed: 'left',
      width: '8%',
      render: (field) => (
        <Form.Item
          label="SERVICE UNITS"
          className="mb-0"
          name={[field.name, UB04_FIELDS.serv_units_46]}
          rules={[getYupValidatorServiceTable(t, UB04_FIELDS.serv_units_46)]}
        >
          <Input style={{ minWidth: '70px' }} onInput={toPositiveFloat} placeholder="1234567890" />
        </Form.Item>
      )
    },
    {
      title: `47. TOTAL CHARGES`,
      //fixed: 'left',
      width: '8%',
      key: '47_total_charges',
      render: (field) => (
        <Form.Item
          className="mb-0"
          label="TOTAL CHARGES"
          name={[field.name, UB04_FIELDS.total_charges_47]}
          rules={[getYupValidatorServiceTable(t, UB04_FIELDS.total_charges_47)]}
        >
          <Input
            style={{ minWidth: '60px' }}
            prefix="$"
            allowClear
            onPaste={toCurrencyPaste}
            onInput={toCurrency}
            className="w-full"
          />
        </Form.Item>
      )
    },
    {
      title: `48. NON-COVERED CHARGES`,
      key: 'nonCoveredCharges',
      //fixed: 'left',
      width: '8%',
      render: (field) => (
        <Form.Item
          className="mb-0"
          label="NON-COVERED CHARGES"
          name={[field.name, UB04_FIELDS.nonCoveredCharges_48]}
          rules={[
            getYupValidatorServiceTable(t, UB04_FIELDS.nonCoveredCharges_48),
            getYupWarningServiceTable(t, UB04_FIELDS.nonCoveredCharges_48)
          ]}
        >
          <Input
            style={{ minWidth: '60px' }}
            prefix="$"
            onPaste={toCurrencyPaste}
            allowClear
            onInput={toCurrency}
            className="w-full"
          />
        </Form.Item>
      )
    },
    {
      title: `49. SUM`,
      key: 'totalCharges',
      //fixed: 'left',
      width: '8%',
      render: (field) => (
        <Form.Item
          className="mb-0"
          label="TOTAL CHARGES"
          name={[field.name, UB04_FIELDS.ub49]}
          rules={[getYupValidatorServiceTable(t, UB04_FIELDS.ub49)]}
        >
          <Input style={{ minWidth: '60px' }} prefix="$" readOnly={true} className="w-full" />
        </Form.Item>
      )
    },
    {
      title: ``,
      key: 'actions',
      //fixed: 'left',
      //width: '12%',
      render: (field, _, idx) => (
        <>
          <Modal
            style={{ top: -20 }}
            open={isModalOpen}
            onOk={() => {
              field.remove(modalRow);
              showModal(false);
            }}
            onCancel={() => showModal(false)}
            centered
            closable={false}
          >
            <div>
              <InfoCircleOutlined style={{ color: '#faad14' }} />
              {' ' + t('hcfa:form.confirmMessage.deleteService')}
            </div>
          </Modal>
          <IconButton
            //style={{ width: '50px' }}
            className="icon-btn icon-btn--primary-outline icon-btn--md about-button"
            // onClick={() => {
            //   field.remove(idx);
            // }}
            onClick={() => {
              setModalRow(field.name);
              showModal(true);
            }}
            variant="outline"
            icon={<DeleteOutlined />}
            aria-label={t('delete')}
            tooltip={t('delete')}
            type="button"
          />
        </>
      )
    }
  ];

  function handleAddServiceUbKeyDown(e: any) {
    if (e.key === 'Tab') {
      e.preventDefault();
      const nextInput = document.getElementById('basic_50a_payer');
      if (nextInput) {
        nextInput.focus();
      }
    }
  }

  return (
    <>
      <Form.List name="42Services">
        {(fields, { add, remove }) => {
          total.current = fields.length;
          return (
            <>
              <Table
                dataSource={fields.map((field) => ({ ...field, remove }))}
                columns={columns.map((col: any, index) => ({
                  ...col,
                  title: (
                    <span className="form_label_color" key={index}>
                      {col.title}
                    </span>
                  )
                }))}
                pagination={{
                  defaultPageSize: PER_PAGE,
                  current: currentPage,
                  onChange: (pg) => {
                    setCurrentPage(pg);
                  }
                }}
                bordered
                scroll={{ x: true }}
              />
              <TableTotals fields={fields} />
              <Button
                type="button"
                onClick={() => onAdd(add)}
                variant="primary"
                style={{ margin: '10px 5px 5px 5px' }}
                leftIcon={<PlusOutlined />}
                disabled={readOnly}
                onKeyDown={(e: any) => handleAddServiceUbKeyDown(e)}
              >
                {t('hcfa:form.addService')}
              </Button>
            </>
          );
        }}
      </Form.List>
      <div style={{ display: 'none' }}>
        <Form.Item
          className="mb-0"
          name={'49ub_total_charges_sum'}
          rules={[getYupValidatorServiceTable(t, UB04_FIELDS.ub_total_charges_sum)]}
        >
          <Input prefix="$" readOnly={true} className="w-full" />
        </Form.Item>
      </div>
    </>
  );
}

export default PaperServicesList;
