import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { Divider, Modal, notification } from 'antd';
import React, { useState, useContext, useEffect } from 'react';

import { Button, ButtonList, FieldError, ValidationErrors } from '@datum-components/react-components';

import { ReactComponent as PaperAirplane } from 'heroicons/outline/paper-airplane.svg';

import { FormContext } from 'components/common/FormProvider';
import { uploadClaimStraightThrough } from 'api/claims';

import FormAttachmentsNew from 'components/common/FormAttachmentsNew';
import { MdOutlineHealthAndSafety, MdOutlineHome } from 'react-icons/md';
// import { TbDental, TbHealthRecognition } from 'react-icons/tb';
import { SECTIONS } from 'config/constants';
import { useNavigate } from 'react-router';
// import { useCanHandleDental } from 'utils/hooks/useCanHandleDental';

function Upload() {
  const { accessToken } = useOidcAccessToken();
  const [loading, setLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const { fileRepresentationsList, setFileRepresentationsList } = useContext(FormContext);
  /**Always HCFA */
  const [section] = useState<SECTIONS>(SECTIONS.HCFA);
  const [validationErrors, setValidationErrors] = React.useState<FieldError[]>([]);
  const navigate = useNavigate();
  // const canHandleDental = useCanHandleDental();

  useEffect(() => {
    if (fileRepresentationsList?.length > 0) {
      setValidationErrors([]);
    }
  }, [fileRepresentationsList?.length]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setFileRepresentationsList([]);
    setValidationErrors([]);
  };

  const handleOk = async () => {
    setLoading(true);

    if (fileRepresentationsList?.length === 0) {
      notification.error({
        message: 'Please add a document and try again.'
      });

      setValidationErrors([
        { propLabel: 'Document', errorMessage: 'Document is missing. Please add document and try again.' }
      ]);

      setLoading(false);
      return false;
    }

    if (!fileRepresentationsList?.some((r) => r.containsOriginalClaim)) {
      notification.error({
        message: 'One attachment must contain original claim'
      });

      setValidationErrors([
        { propLabel: 'Original claim', errorMessage: 'Please select document that contains original claim.' }
      ]);

      setLoading(false);
      return false;
    }

    try {
      const uploadedClaim = await uploadClaimStraightThrough(fileRepresentationsList, section, accessToken);

      handleCancel();

      notification.success({
        message: 'Document uploaded successfully. ID:',
        description: <b>{`${uploadedClaim.docId}`}</b>
      });
    } catch (e) {
      console.log('error', e);
      notification.error({
        message: 'Upload failed. Try again'
      });
    } finally {
      setLoading(false);
    }
  };
  //
  return (
    <>
      <div className="branding-wrapper">
        <div className="box-wrapper">
          <div style={{ padding: '1rem 3rem 0em' }}>
            <Divider orientation="left">UPLOAD CLAIMS</Divider>
          </div>
          <h4 style={{ textAlign: 'center' }}>Click to upload claim.</h4>
          <div className="box upload-claims">
            <ButtonList>
              <ButtonList.Item
                variant="outline"
                style={{ width: 170 }}
                leftIcon={<MdOutlineHome />}
                onClick={() => navigate('/landing')}
              >
                {`Home`}
              </ButtonList.Item>
              <ButtonList.Item
                variant="outline"
                leftIcon={<MdOutlineHealthAndSafety />}
                onClick={() => showModal()}
                style={{ marginLeft: '1rem' }}
              >
                {`Upload Claim`}
              </ButtonList.Item>
              {/* <ButtonList.Item
                variant="outline"
                leftIcon={<TbHealthRecognition />}
                onClick={() => showModal(SECTIONS.UB04)}
                style={{ marginLeft: '1rem' }}
              >
                {`Upload UB Claim`}
              </ButtonList.Item>
              {canHandleDental && (
                <ButtonList.Item
                  variant="outline"
                  leftIcon={<TbDental />}
                  onClick={() => showModal(SECTIONS.DENTAL)}
                  style={{ marginLeft: '1rem' }}
                >
                  {`Upload Dental Claim`}
                </ButtonList.Item>
              )} */}
            </ButtonList>
          </div>
        </div>
      </div>
      <Modal
        className="simple-upload-doc"
        width={1000}
        title={`Upload Claim`}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        destroyOnClose
        footer={[
          <Button key="back" variant="light" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            leftIcon={<PaperAirplane style={{ rotate: '90deg' }} />}
            key="submit"
            variant="primary"
            // loading={loading}
            disabled={loading}
            onClick={handleOk}
            style={{ marginLeft: '1rem' }}
          >
            Send
          </Button>
        ]}
      >
        <ValidationErrors errors={validationErrors} clearErrors={() => setValidationErrors([])} />
        <FormAttachmentsNew form={section} readOnly={false} accept=".pdf, .jpeg, .jpg, .tiff, .png, .tif" isSimple />
      </Modal>
    </>
  );
}

export default Upload;
