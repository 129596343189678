import moment from 'moment';
import phoneCodes from './us-phone-areacodes.json';
import { phoneCodesMap as ub04PhoneCodesMap } from './ub04';
import { HCFA_FIELDS, PHONE_CODES_MAP as hcfaPhoneCodesMap } from './hcfa/fields';
import { HCFA23NumberType, HCFA33PhysicianEnum } from './hcfa/constants';

export function capitalize(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function removeValueFromString(list: string, value: string): string {
  return list.replace(new RegExp(value + ',?'), '');
}

export function tomorrowDate(dateFormat: string) {
  const today = new Date();
  let tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  return moment(tomorrow).format(dateFormat).toString();
}

export const futureDays = (current: moment.Moment) => current && current > moment().endOf('day');
export const futureDaysAndOneYearPast = (current: moment.Moment) => {
  const oneYearAgo = moment().subtract(1, 'year');

  return current && (current < oneYearAgo || current > moment().endOf('day'));
};

function isCliaNumber(authNumber: string) {
  return Boolean(
    authNumber && typeof authNumber === 'string' && authNumber.length === 10 && authNumber[2]?.toLowerCase() === 'd'
  );
}
function isZipCode(authNumber: string) {
  if (!authNumber || typeof authNumber !== 'string') return false;
  const isAllNumeric = /^[0-9]+$/.test(authNumber);
  if (!isAllNumeric) return false;
  return authNumber.length === 5 || authNumber.length === 9;
}

export const getOptions = (arr: any[]) => arr.map((el) => ({ label: el, value: el }));

type FormType = 'ub04' | 'hcfa' | 'dental';
export const serializeFormData = (values: Record<string, any>, form?: FormType) => {
  const updatedData = { ...values };

  // Proceed and parse phone numbers in case if first letters
  // matches with existing phone code
  // const flattenCodes = Object.entries(phoneCodes)
  //   .map(([_state, codes]) => codes)
  //   .flat();
  // Object.entries({ ...ub04PhoneCodesMap, ...hcfaPhoneCodesMap }).forEach(([codeKey, phoneKey]) => {
  //   if (!updatedData[phoneKey]) {
  //     return;
  //   }
  //   flattenCodes.forEach((code) => {
  //     if (!updatedData[phoneKey].startsWith(code)) {
  //       return;
  //     }
  //     updatedData[codeKey] = code;
  //     updatedData[phoneKey] = updatedData[phoneKey].replace(code, '');
  //   });
  // });

  /**is-ambulance in hcfa is boolean that is stored as "true | "false" | undefined | null
   * if cliam comes from Capture field doesn't exist we must extract it from other fields
   */
  if (HCFA_FIELDS.isAmbulance in updatedData) {
    const isAmbulance = updatedData[HCFA_FIELDS.isAmbulance];
    if (isAmbulance === 'true' || isAmbulance === true) {
      updatedData[HCFA_FIELDS.isAmbulance] = true;
    } else {
      updatedData[HCFA_FIELDS.isAmbulance] = false;
    }
  } else {
    let ambulanceValue = false;
    /**if any of ambulance related fields is present then it's true */
    if (
      updatedData[HCFA_FIELDS.hcfaAmbFromLocation] ||
      updatedData[HCFA_FIELDS.hcfaFromAddress] ||
      updatedData[HCFA_FIELDS.hcfaFromCity] ||
      updatedData[HCFA_FIELDS.hcfaFromState] ||
      updatedData[HCFA_FIELDS.hcfaFromZip] ||
      updatedData[HCFA_FIELDS.hcfaAmbToLocation] ||
      updatedData[HCFA_FIELDS.hcfaToState] ||
      updatedData[HCFA_FIELDS.hcfaToAddress] ||
      updatedData[HCFA_FIELDS.hcfaToCity] ||
      updatedData[HCFA_FIELDS.hcfaToZip]
    ) {
      ambulanceValue = true;
    }
    updatedData[HCFA_FIELDS.isAmbulance] = ambulanceValue;
  }

  /**Handle HCFA_33_psysician_checkbox value, on Portal it is saved as field but when it comes from Capture
   * we must extract supplier or physician value from other fields
   */
  if (HCFA_FIELDS.HCFA33psysiciancheckbox in updatedData) {
    updatedData[HCFA_FIELDS.HCFA33psysiciancheckbox] =
      updatedData[HCFA_FIELDS.HCFA33psysiciancheckbox] || HCFA33PhysicianEnum.supplier;
  } else {
    /**not in values claim is from Capture */
    let physicianValue = HCFA33PhysicianEnum.supplier;
    if (updatedData[HCFA_FIELDS.providersPhysicianFirstname] || updatedData[HCFA_FIELDS.providersPhysicianLastname]) {
      /**If has physician specific fields it's physician */
      physicianValue = HCFA33PhysicianEnum.physician;
    }
    updatedData[HCFA_FIELDS.HCFA33psysiciancheckbox] = physicianValue;
  }
  /**Handle '23_number_type' value, on Portal it is saved as field but when it comes from Capture
   * we must extract supplier or physician value from other fields
   */
  if (HCFA_FIELDS.numberType in updatedData) {
    updatedData[HCFA_FIELDS.numberType] = updatedData[HCFA_FIELDS.numberType] || HCFA23NumberType.priorAuthnumber;
  } else {
    updatedData[HCFA_FIELDS.numberType] = HCFA23NumberType.priorAuthnumber;
  }
  // Return serialized and prepared data for ant.d forms
  return updatedData;
};
