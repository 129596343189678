import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { Divider, Form, Select, Table, notification } from 'antd';
import React, { useState } from 'react';
import { Button } from '@datum-components/react-components';
import { getUserLobs, getUserProfileReports, getUserStates } from 'api/reports';
import { useUserData } from 'utils/hooks/useUserData';
import NotAllowedPage from 'components/app/NotAllowedPage';

const LOBS_MOCK = ['test LOB', 'OH', 'IN', 'MS', 'Indiana', 'WV', 'NC', 'Michigan', 'lob', 'Georgia', 'MI'];
const STATES_MOCK = ['7_ins_state - GA', 'user_state', 'GA', 'test User State'];

function UserProfileReports() {
  const { accessToken } = useOidcAccessToken();
  const [docStatusReportList, setDocStatusReportList] = useState([]);
  const [lobs, setLobs] = useState<string[]>(LOBS_MOCK);
  const [states, setStates] = useState<string[]>(STATES_MOCK);
  const { isUserRole } = useUserData();

  React.useEffect(() => {
    // userProfileReportFunction();
    userLobsFunction();
    userStatesFunction();
  }, []);

  const userProfileReportFunction = async (values?: any) => {
    if (accessToken) {
      try {
        const data = await getUserProfileReports({ lob: values.lob, userState: values.state }, accessToken);
        if (data) {
          setDocStatusReportList(data);
        }
      } catch (error: any) {
        console.error('An error occurred:', error);
        notification.error({
          message: 'Could not fetch User Report'
        });
      } finally {
        //
      }
    }
  };

  const userLobsFunction = async () => {
    if (accessToken) {
      try {
        const data = await getUserLobs(accessToken);
        if (data) {
          setLobs(data);
        }
      } catch (error: any) {
        console.error('An error occurred:', error);
        notification.error({
          message: 'Could not fetch LOBs'
        });
      } finally {
        //
      }
    }
  };

  const userStatesFunction = async () => {
    if (accessToken) {
      try {
        const data = await getUserStates(accessToken);
        if (data) {
          setStates(data);
        }
      } catch (error: any) {
        console.error('An error occurred:', error);
        notification.error({
          message: 'Could not fetch States'
        });
      } finally {
        //
      }
    }
  };

  const columns: any = [
    {
      title: 'PROVIDER NAME',
      dataIndex: 'providerName',
      key: 'providerName',
      fixed: 'left'
    },
    {
      title: 'USERNAME',
      dataIndex: 'username',
      key: 'username',
      fixed: 'left'
    },
    {
      title: 'PROVIDER ID',
      dataIndex: 'providerId',
      key: 'providerId'
    },
    {
      title: 'USER STATE',
      dataIndex: 'userState',
      key: 'userState'
    },
    {
      title: 'KEYCLOAK ROLES',
      dataIndex: 'keycloakRoles',
      key: 'keycloakRoles'
    },
    {
      title: 'PROVIDER CITY',
      dataIndex: 'providerCity',
      key: 'providerCity'
    },
    {
      title: 'LOB',
      dataIndex: 'lob',
      key: 'lob'
    },
    {
      title: 'TAX ID',
      dataIndex: 'taxId',
      key: 'taxId'
    },
    {
      title: 'NPI',
      dataIndex: 'npi',
      key: 'npi'
    },
    {
      title: 'PROVIDER ZIP CODE',
      dataIndex: 'providerZipCode',
      key: 'providerZipCode'
    },
    {
      title: 'PROVIDER STATE',
      dataIndex: 'providerState',
      key: 'providerState'
    },
    {
      title: 'LOGIN ID',
      dataIndex: 'loginId',
      key: 'loginId'
    },
    {
      title: 'PROVIDER ADDRESS 1',
      dataIndex: 'providerAddress1',
      key: 'providerAddress1'
    },
    {
      title: 'PROVIDER ADDRESS 2',
      dataIndex: 'providerAddress2',
      key: 'providerAddress2'
    }
  ];
  if (isUserRole) return <NotAllowedPage />;
  return (
    <div className="branding-wrapper">
      <div className="box-wrapper">
        <div style={{ padding: '1rem 3rem 0em' }}>
          <Divider orientation="left">
            <b>USER PROFILE REPORTS</b>
          </Divider>
        </div>
        <div style={{ marginTop: '2rem' }}>
          <Form
            name="basic"
            layout="vertical"
            autoComplete="off"
            onFinish={userProfileReportFunction}
            style={{ display: 'flex', alignItems: 'flex-end', gap: '1rem', padding: '0 1rem' }}
          >
            {/* <Form.Item name="username" label="Username">
              <Input placeholder="Username" allowClear />
            </Form.Item> */}
            <Form.Item rules={[{ required: true, message: 'LOB is required' }]} label="LOB" name="lob">
              <Select
                style={{ width: '150px' }}
                placeholder="Please select"
                allowClear
                options={lobs.map((el) => ({ label: el, value: el }))}
                showSearch
              />
            </Form.Item>
            <Form.Item rules={[{ required: true, message: 'State is required' }]} label="State" name="state">
              <Select
                style={{ width: '150px' }}
                placeholder="Please select"
                allowClear
                options={states.map((el) => ({ label: el, value: el }))}
                showSearch
              />
            </Form.Item>
            <Form.Item>
              <div className="theme ant-col theme ant-form-item-label">
                <label htmlFor="basic_filter" title="Filter" style={{ opacity: 0 }}>
                  Filter
                </label>
              </div>
              <Button type="submit" style={{ width: '100%' }} variant="primary">
                Reports
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div style={{ padding: '0 1rem' }}>
          <Table
            scroll={{ x: 1300 }}
            bordered
            style={{ width: '100%' }}
            dataSource={docStatusReportList}
            columns={columns}
          />
        </div>
      </div>
    </div>
  );
}

export default UserProfileReports;
