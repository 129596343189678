import React, { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ColumnsType } from 'antd/es/table';
import { Form, Input, Modal, Select, Table } from 'antd';
import { DeleteOutlined, PlusOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, IconButton } from '@datum-components/react-components';

import {
  EMGS,
  FAMILY_PLANS,
  MODIFIERS,
  PLACE_OF_SERVICES,
  QUALITY_IDS,
  EPSDT,
  NDC_UNIT_CODES
} from '../../../utils/hcfa/constants';
import { HCFA_FIELDS } from '../../../utils/hcfa/fields';
import { DATE_FORMAT } from '../../../utils/hcfaConstants';
import { getYupValidatorServiceTable, getYupWarningServiceTable } from '../../../utils/hcfa/validations';
import { getOptions } from '../../../utils/common';

import { toCurrency, toCurrencyPaste } from 'helpers/toCurrency';
import { toPositiveNumber } from 'helpers/toPositiveNumber';

import { FormContext } from 'components/common/FormProvider';
import { toPositiveFloat } from 'helpers/toPositiveFloat';
import { toAlphaNumUppercase, toModifier } from 'helpers/toAlphaNumUppercase';
import { onSearchSelect } from 'utils/search/search';
import DatePickerAutoPopulate from 'components/common/DatePickerAutoPopulate';
import TableTotals from './TableTotals';

interface Props {
  readOnly: boolean;
  setDisabledSwitch: React.Dispatch<React.SetStateAction<boolean>>;
}

const PER_PAGE = 6;

function PaperServicesList({ readOnly, setDisabledSwitch }: Props): JSX.Element {
  const { t } = useTranslation();
  const { form } = useContext(FormContext);
  const [currentPage, setCurrentPage] = useState(1);
  const total = useRef(0);

  const okButtonRef = React.useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalRow, setModalRow] = useState<number>();

  const showModal = (open: boolean) => {
    setIsModalOpen(open);
  };

  const onAdd = (add: (defaultValue?: any, insertIndex?: number | undefined) => void) => {
    const totalCount = total.current;
    if (totalCount % PER_PAGE === 0) {
      setCurrentPage(totalCount / PER_PAGE + 1);
    }
    add();
    const tableValues = form?.getFieldValue(['24Table']);

    if (tableValues && tableValues.length) {
      const idx = tableValues.length - 1;
      setTimeout(() => {
        const inputEl = document.getElementById(`24a_dos_from__${idx}`);
        if (inputEl) inputEl.focus();
      }, 10);
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: '24.',
      dataIndex: 'key',
      width: '1%',
      render(_v, _r, _i) {
        return _v + 1;
      }
    },
    {
      title: `a. ${t('common:fields.date.service')}`,
      children: [
        {
          title: t('common:from'),
          key: '24a_dos_from',
          width: '5%',
          render: (field) => (
            <Form.Item
              rules={[getYupValidatorServiceTable(t, '24a_dos_from'), getYupWarningServiceTable(t, '24a_dos_from')]}
              name={[field.name, '24a_dos_from']}
              className="mb-0"
            >
              <DatePickerAutoPopulate
                suffixIcon={null}
                inputId={`24a_dos_from__${field.name}`}
                placeholder={t('common:fields.dateFormat')}
                panelRender={() => false}
                format={DATE_FORMAT}
                style={{ width: '90px' }}
                // @ts-ignore
                name={[field.name, '24a_dos_from']}
                form={form!}
                tableName={'24Table'}
              />
            </Form.Item>
          )
        },
        {
          title: t('common:to'),
          key: '24a_dos_to',
          width: '5%',
          render: (field) => (
            <Form.Item
              rules={[getYupValidatorServiceTable(t, '24a_dos_to'), getYupWarningServiceTable(t, '24a_dos_to')]}
              name={[field.name, '24a_dos_to']}
              className="mb-0"
            >
              <DatePickerAutoPopulate
                suffixIcon={null}
                placeholder={t('common:fields.dateFormat')}
                panelRender={() => false}
                format={DATE_FORMAT}
                style={{ width: '90px' }}
                // @ts-ignore
                name={[field.name, '24a_dos_to']}
                form={form!}
                tableName={'24Table'}
              />
            </Form.Item>
          )
        }
      ]
    },
    {
      title: `b. ${t('common:fields.servicePlace')}`,
      key: '24b_place_of_service',
      width: '5%',
      render: (field) => (
        <Form.Item
          rules={[getYupValidatorServiceTable(t, '24b_place_of_service')]}
          name={[field.name, '24b_place_of_service']}
          className="mb-0"
        >
          <Select
            showSearch
            options={getOptions(PLACE_OF_SERVICES)}
            style={{ width: 65 }}
            allowClear
            onSearch={(input) => {
              onSearchSelect(form, ['24Table', field.name, '24b_place_of_service'], input, PLACE_OF_SERVICES);
            }}
          />
        </Form.Item>
      )
    },
    {
      title: `c. ${t('common:fields.emg')}`,
      key: '24c_emg',
      width: '5%',
      render: (field) => (
        <Form.Item name={[field.name, '24c_emg']} className="mb-0">
          <Select
            options={getOptions(EMGS)}
            style={{ width: 50 }}
            allowClear
            showSearch
            onSearch={(input) => {
              onSearchSelect(form, ['24Table', field.name, '24c_emg'], input, EMGS);
            }}
          />
        </Form.Item>
      )
    },
    {
      title: `d. ${t('common:fields.procedures')} (${t('common:fields.proceduresDescription')})`,
      children: [
        {
          title: t('common:fields.cpt'),
          key: '24d_procedure',
          width: '5%',
          render: (field) => (
            <Form.Item
              name={[field.name, '24d_procedure']}
              rules={[getYupValidatorServiceTable(t, HCFA_FIELDS.procedure24)]}
              className="mb-0"
            >
              <Input style={{ width: '70px' }} onInput={toAlphaNumUppercase} />
            </Form.Item>
          )
        },
        {
          title: 'NDC Data / MODIFIER',
          key: `24d_ndc_and_modifiers`,
          width: '20%',
          render: (field) => (
            <div>
              <div className="flex" style={{ marginBottom: '5px' }}>
                <Form.Item
                  name={[field.name, HCFA_FIELDS.ndcCode]}
                  className="mb-0"
                  rules={[getYupValidatorServiceTable(t, HCFA_FIELDS.ndcCode)]}
                >
                  <Input onInput={toPositiveNumber} placeholder={'NDC CODE'} style={{ width: '120px' }} />
                </Form.Item>
                <Form.Item
                  name={[field.name, HCFA_FIELDS.ndcQuantity]}
                  className="mb-0"
                  rules={[getYupValidatorServiceTable(t, HCFA_FIELDS.ndcQuantity)]}
                >
                  <Select
                    maxLength={2}
                    placeholder={'NDC QTY'}
                    options={getOptions(NDC_UNIT_CODES)}
                    allowClear
                    showSearch
                    style={{ width: '120px' }}
                    onSearch={(input) => {
                      onSearchSelect(
                        form,
                        [HCFA_FIELDS.servicesTable, field.name, HCFA_FIELDS.ndcQuantity],
                        input,
                        NDC_UNIT_CODES
                      );
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name={[field.name, HCFA_FIELDS.ndcUnit]}
                  className="mb-0"
                  rules={[getYupValidatorServiceTable(t, HCFA_FIELDS.ndcUnit)]}
                >
                  <Input onInput={toPositiveFloat} placeholder={'NDC UNITS'} style={{ width: '120px' }} />
                </Form.Item>
                <Form.Item
                  // rules={[getYupValidatorServiceTable(t, HCFA_FIELDS.anesthesiaDuration)]}
                  rules={[getYupWarningServiceTable(t, HCFA_FIELDS.anesthesiaDuration)]}
                  name={[field.name, HCFA_FIELDS.anesthesiaDuration]}
                  className="mb-0"
                >
                  <Input
                    onInput={toPositiveNumber}
                    placeholder="Anesthesia duration (minutes)"
                    style={{ width: '120px' }}
                  />
                </Form.Item>
              </div>
              <div className="flex">
                {[1, 2, 3, 4].map((modifierItem, i) => (
                  <Form.Item
                    key={`${i}`}
                    rules={[getYupValidatorServiceTable(t, `24d_modifier_${i + 1}`)]}
                    name={[field.name, `24d_modifier_${i + 1}`]}
                    className="mb-0"
                  >
                    {/* <Select
                      allowClear
                      placeholder={`M${i + 1}`}
                      showSearch
                      options={getOptions(MODIFIERS)}
                      style={{ width: '120px' }}
                      onSearch={(input) => {
                        onSearchSelect(form, ['24Table', field.name, `24d_modifier_${i + 1}`], input, MODIFIERS);
                      }}
                    /> */}
                    <Input onInput={toModifier} placeholder={`M${i + 1}`} style={{ width: '120px' }} />
                  </Form.Item>
                ))}
              </div>
            </div>
          )
        }
        // {
        //   title: `Anesthesia duration (minutes)`,
        //   key: '24_anesthesia_duration',
        //   render: (field) => (
        //     <Form.Item
        //       // rules={[getYupValidatorServiceTable(t, HCFA_FIELDS.anesthesiaDuration)]}
        //       {...field}
        //       name={[field.name, HCFA_FIELDS.anesthesiaDuration]}
        //       className="mb-0"
        //     >
        //       <Input onInput={toPositiveNumber} placeholder="Anesthesia duration (minutes)" />
        //     </Form.Item>
        //   )
        // }
      ]
    },
    {
      title: `e. ${t('common:fields.diagnosisPointer')}`,
      key: '24e_dx_pointer',
      width: '5%',
      render: (field) => (
        <Form.Item
          name={[field.name, '24e_dx_pointer']}
          className="mb-0"
          rules={[getYupValidatorServiceTable(t, HCFA_FIELDS.diagnosisPointer)]}
        >
          <Input
            placeholder="ABC"
            style={{ minWidth: '100px' }}
            onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
          />
        </Form.Item>
      )
    },
    {
      title: `f. ${t('common:fields.charges')}`,
      key: '24f_charges',
      width: '5%',
      render: (field) => (
        <Form.Item
          name={[field.name, '24f_charges']}
          className="mb-0"
          rules={[getYupValidatorServiceTable(t, HCFA_FIELDS.chargesf24)]}
        >
          <Input
            onPaste={toCurrencyPaste}
            onInput={toCurrency}
            allowClear
            prefix="$"
            placeholder="500.00"
            style={{ minWidth: '100px' }}
            // onChange={(ev) => onChange(ev, field)}
          />
        </Form.Item>
      )
    },
    {
      title: `g. ${t('common:fields.daysUnits')}`,
      width: '5%',
      key: '24g_units',
      render: (field) => (
        <Form.Item
          name={[field.name, '24g_units']}
          className="mb-0"
          rules={[getYupValidatorServiceTable(t, '24g_units')]}
        >
          <Input style={{ minWidth: '100px' }} onInput={toPositiveFloat} placeholder="1" />
        </Form.Item>
      )
    },
    {
      title: `h. epsdt family plan`,
      children: [
        {
          title: 'EPSDT',
          key: '24_epsdt_fam_plan',
          width: '5%',
          render: (field) => (
            <>
              <Form.Item
                name={[field.name, '24_epsdt']}
                style={{ marginBottom: '5px' }}
                rules={[getYupValidatorServiceTable(t, HCFA_FIELDS.epsdt24)]}
              >
                <Select
                  options={getOptions(EPSDT)}
                  placeholder="EPSDT"
                  style={{ width: '55px' }}
                  allowClear
                  showSearch
                  onSearch={(input) => {
                    onSearchSelect(form, ['24Table', field.name, '24_epsdt'], input, EPSDT);
                  }}
                />
              </Form.Item>
              <Form.Item name={[field.name, '24h_family_plan']} className="mb-0">
                <Select
                  options={getOptions(FAMILY_PLANS)}
                  placeholder="FAMILY PLAN"
                  style={{ width: '55px' }}
                  allowClear
                  showSearch
                  onSearch={(input) => {
                    onSearchSelect(form, ['24Table', field.name, '24h_family_plan'], input, FAMILY_PLANS);
                  }}
                />
              </Form.Item>
            </>
          )
        }
        // {
        //   title: 'Family plan',
        //   key: '24h_family_plan',
        //   render: (field) => (
        //     <Form.Item {...field} name={[field.name, '24h_family_plan']} className="mb-0">
        //       <Select
        //         options={getOptions(FAMILY_PLANS)}
        //         style={{ minWidth: 53 }}
        //         allowClear
        //         showSearch
        //         onSearch={(input) => {
        //           onSearchSelect(form, ['24Table', field.name, '24h_family_plan'], input, FAMILY_PLANS);
        //         }}
        //       />
        //     </Form.Item>
        //   )
        // }
      ]
    },
    {
      title: `i. ${t('common:fields.idQual')}`,
      key: '24i_render_id_qual',
      width: '5%',
      render: (field) => (
        <Form.Item
          rules={[getYupValidatorServiceTable(t, '24i_render_id_qual')]}
          name={[field.name, '24i_render_id_qual']}
          className="mb-0"
        >
          <Select
            maxLength={2}
            options={getOptions(QUALITY_IDS)}
            style={{ width: '65px' }}
            allowClear
            showSearch
            onSearch={(input) => {
              onSearchSelect(
                form,
                [HCFA_FIELDS.servicesTable, field.name.toLocaleString(), '24i_render_id_qual'],
                input,
                QUALITY_IDS
              );
            }}
          />
        </Form.Item>
      )
    },
    {
      title: `j. ${t('common:fields.renderingProviderId')}`,
      key: '24j_render_id_npi',
      width: '5%',
      render: (field) => (
        <>
          <Form.Item
            name={[field.name, HCFA_FIELDS.renderId24]}
            style={{ marginBottom: '5px' }}
            rules={[getYupValidatorServiceTable(t, HCFA_FIELDS.renderId24)]}
          >
            <Input
              placeholder="PROVIDER ID"
              style={{ width: '110px' }}
              onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
            />
          </Form.Item>
          <Form.Item
            name={[field.name, '24j_render_npi']}
            className="mb-0"
            rules={[
              getYupValidatorServiceTable(t, HCFA_FIELDS.renderNPI24)
              //getYupWarningServiceTable(t, HCFA_FIELDS.renderNPI24)
            ]}
          >
            <Input style={{ width: '110px' }} onInput={toPositiveNumber} placeholder="NPI" />
          </Form.Item>
        </>
      )
      // children: [
      //   {
      //     title: 'ID',
      //     key: '24j_render_id_npi',
      //     render: (field) => (
      //       <>
      //         <Form.Item
      //           {...field}
      //           name={[field.name, HCFA_FIELDS.renderId24]}
      //           className="mb-0"
      //           rules={[getYupValidatorServiceTable(t, HCFA_FIELDS.renderId24)]}
      //         >
      //           <Input
      //             placeholder="PROVIDER ID"
      //             style={{ minWidth: 85 }}
      //             onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
      //           />
      //         </Form.Item>
      //         <Form.Item
      //           {...field}
      //           name={[field.name, '24j_render_npi']}
      //           className="mb-0"
      //           rules={[getYupValidatorServiceTable(t, HCFA_FIELDS.renderNPI24)]}
      //         >
      //           <Input onInput={toPositiveNumber} placeholder="NPI" />
      //         </Form.Item>
      //       </>
      //     )
      //   }
      //   // {
      //   //   title: 'NPI',

      //   //   key: '24j_render_npi',
      //   //   render: (field) => (
      //   //     <Form.Item
      //   //       {...field}
      //   //       name={[field.name, '24j_render_npi']}
      //   //       className="mb-0"
      //   //       rules={[getYupValidatorServiceTable(t, HCFA_FIELDS.renderNPI24)]}
      //   //     >
      //   //       <Input onInput={toPositiveNumber} placeholder="12345" />
      //   //     </Form.Item>
      //   //   )
      //   // }
      // ]
    },

    {
      title: '',
      key: 'actions',
      //fixed: 'right',
      width: '1%',
      render: (field) => (
        <>
          <Modal
            style={{ top: -20 }}
            open={isModalOpen}
            onOk={() => {
              field.remove(modalRow);
              showModal(false);
            }}
            onCancel={() => showModal(false)}
            centered
            closable={false}
          >
            <div>
              <InfoCircleOutlined style={{ color: '#faad14' }} />
              {' ' + t('hcfa:form.confirmMessage.deleteService')}
            </div>
          </Modal>
          <IconButton
            ref={okButtonRef}
            className="icon-btn icon-btn--primary-outline icon-btn--md"
            variant="outline"
            icon={<DeleteOutlined />}
            aria-label={t('delete')}
            tooltip={t('delete')}
            type={'button'}
            id={'deleteButton'}
            onClick={() => {
              setModalRow(field.name);
              showModal(true);
            }}
          />
        </>
      )
    }
  ];

  return (
    <>
      <Form.List name="24Table">
        {(fields, { add, remove }) => {
          total.current = fields.length;
          return (
            <>
              <Table
                dataSource={fields.map((field) => ({ ...field, remove }))}
                columns={columns.map((col: any, index) => ({
                  ...col,
                  title: <span className="form_label_color">{col.title}</span>,
                  action: <div>Hola</div>
                }))}
                size="small"
                pagination={{
                  defaultPageSize: PER_PAGE,
                  current: currentPage,
                  onChange: (pg) => {
                    setCurrentPage(pg);
                  }
                }}
                bordered
                scroll={{ x: true }}
                //style={{ minWidth: '2100px' }}
                className="service-list-table"
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {fields && fields.length > 0 && <TableTotals setDisabledSwitch={setDisabledSwitch} />}
              </div>
              <Button
                type="button"
                onClick={() => onAdd(add)}
                variant="primary"
                style={{ margin: '10px 5px 5px 5px' }}
                leftIcon={<PlusOutlined />}
                disabled={readOnly}
              >
                {t('hcfa:form.addService')}
              </Button>
            </>
          );
        }}
      </Form.List>
    </>
  );
}

export default PaperServicesList;
