import * as React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import reportWebVitals from 'reportWebVitals';
import { RapidComponentProviders, PageMessage, PageMessageProps } from '@datum-components/react-components';
//import { ReactComponent as Logo } from 'assets/CareSourceLogo.svg';
import Logo from 'components/common/Logo';
import { OidcProvider, useOidc } from '@axa-fr/react-oidc';
import { OidcConfiguration } from '@axa-fr/react-oidc/dist/vanilla/oidc';
import { checkValidAuthConfig } from 'utils/checkAuthConfig';

import 'antd/dist/antd.min.css';
import '@datum-components/react-components/dist/index.css';
import './config/i18n';
import './styles/index.scss';
import App from './App';
import IndexProvider from 'context';
import { AUTH_PROVIDER_TYPES, CONFIG, LOGOUT_URL } from 'config/constants';
import { t as i18n } from 'i18next';
import { BrandingContextProvider } from 'components/common/BrandingProvider';
import LogoutPage from 'pages/LogoutPage';
import * as pdfjs from 'pdfjs-dist';
import { putFormProvidersDataInGlobalObject } from 'utils/hcfa/putFormProvidersDataInGlobalObject';
import moment from 'moment';

/**Set pivot year globaly for date format MMDDYY */
moment.parseTwoDigitYear = (yearString: string) => {
  const inputYear = Number(yearString);
  const pivotYear = new Date().getFullYear() % 1000;
  if (inputYear > pivotYear) {
    return 1900 + inputYear;
  } else {
    return 2000 + inputYear;
  }
};

/**Make sure not to be undefined */
putFormProvidersDataInGlobalObject({});

/**FROM CDN */
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`;
/**From public folder */
const publicUrl = window.location.origin;
pdfjs.GlobalWorkerOptions.workerSrc = `${publicUrl}/pdf.worker.min.js`;

const root = ReactDOM.createRoot(document.getElementById('root') ?? document.body);

const _extras =
  ![AUTH_PROVIDER_TYPES.KEYCLOAK as string, AUTH_PROVIDER_TYPES.OKTA as string].includes(CONFIG.AUTH_PROVIDER_TYPE) &&
  !!CONFIG.AUTH_AUD
    ? { extras: { audience: CONFIG.AUTH_AUD } }
    : {};

const _auth0AuthorityConfig = {
  authority_configuration: {
    issuer: CONFIG.AUTH_AUTHORITY + '/',
    authorization_endpoint: `${CONFIG.AUTH_AUTHORITY}/authorize`,
    revocation_endpoint: `${CONFIG.AUTH_AUTHORITY}/oauth/revoke`,
    token_endpoint: `${CONFIG.AUTH_AUTHORITY}/oauth/token`,
    end_session_endpoint: `${CONFIG.AUTH_AUTHORITY}/logout`,
    userinfo_endpoint: `${CONFIG.AUTH_AUTHORITY}/userinfo`
  }
};

const _customAuthorityConfig = {
  authority_configuration: {
    issuer: CONFIG.AUTH_CUSTOM_ISSUER,
    authorization_endpoint: CONFIG.AUTH_CUSTOM_AUTH,
    revocation_endpoint: CONFIG.AUTH_CUSTOM_REVOCATION,
    token_endpoint: CONFIG.AUTH_CUSTOM_TOKEN,
    end_session_endpoint: CONFIG.AUTH_CUSTOM_END_SESSION,
    userinfo_endpoint: CONFIG.AUTH_CUSTOM_USER_INFO
  }
};

const _customConf = [AUTH_PROVIDER_TYPES.AUTH0 as string].includes(CONFIG.AUTH_PROVIDER_TYPE)
  ? _auth0AuthorityConfig
  : _customAuthorityConfig;

const _customAuth = [AUTH_PROVIDER_TYPES.KEYCLOAK as string, AUTH_PROVIDER_TYPES.OKTA as string].includes(
  CONFIG.AUTH_PROVIDER_TYPE
)
  ? {}
  : _customConf;

const authConfig: OidcConfiguration = {
  client_id: CONFIG.AUTH_CLIENT_ID,
  redirect_uri: window.location.origin + '/authentication/callback',
  silent_redirect_uri: window.location.origin + '/authentication/silent-callback', // Optional activate silent-signin that use cookies between OIDC server and client javascript to restore the session
  scope: `openid profile ${CONFIG.AUTH_SCOPES}`,
  authority: CONFIG.AUTH_AUTHORITY,
  /**Comment out to make service worker available */
  // service_worker_relative_url: '/OidcServiceWorker.js',
  // service_worker_only: true,
  ..._extras,
  ..._customAuth
};

// setTimeout(async () => {
//   console.log('Simulating session expiration...');
//   handleLogout();
// }, 10000);

const _validationAuthConfig = checkValidAuthConfig(CONFIG);

function CustomPageMessage({ type }: { type: string }): JSX.Element {
  const { logout } = useOidc();

  const handleLogout = React.useCallback(async () => {
    try {
      //PARAMS ONLY FOR AUTH0
      if (CONFIG.AUTH_PROVIDER_TYPE === AUTH_PROVIDER_TYPES.AUTH0)
        await logout('', {
          client_id: window._env_.AUTH_CLIENT_ID,
          returnTo: window.location.origin
        });
      else await logout(LOGOUT_URL);
    } catch (error) {
      console.error(error);
    }
  }, [logout]);

  React.useEffect(() => {
    if (type === 'sessionLost') {
      handleLogout();
    }
  }, []);

  const props = React.useMemo(() => {
    const _props = {
      appTitle: CONFIG.APP_NAME,
      //logo: <Logo />,
      statusTitle: i18n(`auth:${type}.title`)
    } as PageMessageProps;

    switch (type) {
      case 'authenticating':
      case 'callbackSuccess':
      case 'loading': {
        _props.status = 'loading';
        break;
      }
      case 'serviceWorkerNotSupported':
      case 'authenticatingError':
      case 'sessionLost': {
        _props.status = 'error';
        _props.statusDescription = i18n(`auth:${type}.description`);
        _props.backFn = async () => {
          await handleLogout();
        };
        _props.backLabel = i18n('back');
        break;
      }
    }
    return _props;
  }, [handleLogout, type]);

  return (
    <>
      <PageMessage {...props} />
      {type === 'sessionLost' && <LogoutPage />}
    </>
  );
}

if (!_validationAuthConfig.length)
  root.render(
    <Router>
      <OidcProvider
        configuration={authConfig}
        loadingComponent={() => <CustomPageMessage type="loading" />}
        authenticatingErrorComponent={() => <CustomPageMessage type="authenticatingError" />}
        authenticatingComponent={() => <CustomPageMessage type="authenticating" />}
        sessionLostComponent={() => <CustomPageMessage type="sessionLost" />}
        serviceWorkerNotSupportedComponent={() => <CustomPageMessage type="serviceWorkerNotSupported" />}
        callbackSuccessComponent={() => <CustomPageMessage type="callbackSuccess" />}
      >
        <IndexProvider>
          <BrandingContextProvider>
            <RapidComponentProviders>
              <App />
            </RapidComponentProviders>
          </BrandingContextProvider>
        </IndexProvider>
      </OidcProvider>
    </Router>
  );
else
  root.render(
    <PageMessage
      appTitle={CONFIG.APP_NAME}
      logo={<Logo />}
      statusTitle={i18n(`auth:configurationError.title`)}
      status="error"
      statusDescription={i18n(`auth:configurationError.description`, { envs: _validationAuthConfig.join(', ') })}
    />
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
